export enum BookingType {
	Standard = 1,
	Interview = 2,
	Trial = 3,
	Perm = 4,
	Tutor = 5,
}

export enum BookingSubType {
	TutorStandard = 1,
	TutorMentor = 2,
	TutorBasic = 3,
	FaceToFace = 4,
}
