export enum CandidateSourceEnum {
	CompanyWebsite = "Company Website",
	iCanTeachUKWebsite = "iCan Teach UK Website",
	ApplytoEducation = "Apply to Education",
	CVLibraryCVSearch = "CV Library - CV Search",
	CVLibraryAdvert = "CV Library Advert",
	CVLibraryWatchdog = "CV Library - Watchdog",
	GuardianCVSearch = "Guardian- CV Search",
	GuardianAdvert = "Guardian Advert",
	IndeedCVSearch = "Indeed - CV Search",
	IndeedAdvert = "Indeed Advert",
	ReedCVSearch = "Reed - CV Search",
	ReedAdvert = "Reed Advert",
	ReedWatchdog = "Reed – Watchdog",
	SENployAdvert = "SENploy - Advert",
	TotalJobs = "Total Jobs",
	OtherJobBoard = "Other Job Board",
	Google = "Google",
	LinkedIn = "LinkedIn",
	LinkedinRecruiter = "Linkedin Recruiter",
	LinkedInAdvert = "LinkedIn Advert",
	ReferralSchool = "Referral - School",
	ReferralCandidate = "Referral - Candidate",
	UKUniversity = "UK University",
	IrishUniversity = "Irish University",
	CanadianUniversity = "Canadian University",
	MarketingCampaign = "Marketing Campaign",
	Woo = "Woo",
	JobShow = "Job Show",
	RecruitmentFair = "Recruitment Fair",
	CareerFair = "Career Fair",
	Volcanic = "Volcanic",
	Broadbean = "Broadbean",
	Headhunt = "Headhunt",
	Referral = "Referral",
	Direct = "Direct",
	Other = "Other",
}

export enum AlternateCandidateSourceEnum {
	CompanyWebsite = "Company Website",
	DirectCall = "Direct Call",
	CvLibraryWatchdog = "CV Library Watchdog",
	CvLibraryAdvert = "CV Library Advert",
	CvLibraryCvSearch = "CV Library CV Search",
	ReedWatchdog = "Reed Watchdog",
	ReedAdvert = "Reed Advert",
	ReedCvSearch = "Reed CV Search",
	IndeedWatchdog = "Indeed Watchdog",
	IndeedAdvert = "Indeed Advert",
	IndeedCvSearch = "Indeed CV Search",
	JobCentre = "Job Centre",
	ReturningCandidate = "Returning Candidate",
	ReferralCandidate = "Referral Candidate",
	ReferralClient = "Referral Client",
	TrainingProvider = "Training Provider",
	RecruitmentFair = "Recruitment Fair",
	UniversityCollege = "University/College",
}
