import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleLoading, setAlert } from "../../state/creators";
import { ModalWrapper, ModalHeader, ModalContent, ModalFooter, ModalClose } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import { StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";
import { ApiResultModel } from "../../_models/api.result.model";
import FormError from "../forms/errors/http";
import { EventTargetType } from "../../_types/form";
import { IHttpResponse } from "../../functions/http-functions/http";
import { useAuth } from "../../state/context/auth.store";
import { AlertType } from "../../state/reducers/setAlert";
import RowStyled from "../../styles/layout/row";
import ColStyled from "../../styles/layout/col";
import { ValidateTargetCreate } from "../../functions/validators";
import { ITarget, ITargetFieldErrors, TargetFieldErrorsModel, TargetModel } from "../../_models/data.target.model";
import { AddTarget, UpdateTarget } from "../../functions/http-functions/targets";
import SelectInput from "../forms/inputs/select";
import { UserTargetTypes } from "../../config/formData";
import DateRangeInput from "../forms/inputs/dateRange";
import TextInput from "../forms/inputs/text";
import moment from "moment";
import HelpModal from "./help.modal";
import { UserTargetReportPeriodHelpText } from "../../config/helpText";
import { Warning } from "../forms/errors/input";

type AssigneeModalProps = {
	target?: ITarget;
	userId: string;
	closeModal: any;
	complete: () => void;
	toggleLoading: (loading: boolean) => void;
	setAlert: (alert: AlertType) => void;
};

const AddTargetModal: React.FC<AssigneeModalProps> = ({ target: targetProp, userId, closeModal, complete, toggleLoading, setAlert }) => {
	const { auth } = useAuth();
	const [target, setTarget] = useState<ITarget>(new TargetModel(targetProp ?? { user: userId, start: null, end: null, type: null, value: null, reportPeriod: 1 }));
	const [coverage, setCoverage] = useState<Date[]>(targetProp ? getCoverage(targetProp.start, targetProp.end) : []);
	const [fieldErrors, setFieldErrors] = useState<ITargetFieldErrors>(new TargetFieldErrorsModel({}));
	const [error, setError] = useState(null);

	useEffect(() => {
		if (coverage?.length) {
			setTarget((prevTarget) => ({
				...prevTarget,
				start: coverage[0],
				end: coverage[coverage.length - 1],
			}));
		} else {
			setTarget((prevTarget) => ({
				...prevTarget,
				start: null,
				end: null,
			}));
		}
	}, [coverage]);

	const handleChange = (e: EventTargetType) => {
		setTarget((prevTarget) => ({
			...prevTarget,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// reset errors
		setFieldErrors(new TargetFieldErrorsModel({}));
		setError(null);

		const ValidateStep = ValidateTargetCreate({ ...target });
		if (ValidateStep.valid) {
			targetProp ? updateTarget() : addTarget();
		} else {
			for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
				setFieldErrors((prevState) => ({
					...prevState,
					[item.name]: item.error,
				}));
			}
			setError(<FormError errors={["There were one or more errors validating your submission. The fields have been highlighted above."]} />);
		}
	};

	const addTarget = async () => {
		try {
			toggleLoading(true);
			await AddTarget(auth.token, target);
			setAlert({ type: "success", message: "User Target Added Successfully" });
			complete();
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setAlert({ type: "error", message: ErrorResult.message });
			setError(<FormError errors={[ErrorResult.message]} />);
		} finally {
			toggleLoading(false);
		}
	};

	const updateTarget = async () => {
		try {
			toggleLoading(true);
			await UpdateTarget(auth.token, target, targetProp?._id);
			setAlert({ type: "success", message: "User Target Updated Successfully" });
			complete();
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setAlert({ type: "error", message: ErrorResult.message });
			setError(<FormError errors={[ErrorResult.message]} />);
		} finally {
			toggleLoading(false);
		}
	};

	const reportPeriodOptions = [
		{ Name: "Full", ID: 1 },
		{ Name: "Weekly", ID: 2 },
		{ Name: "Monthly", ID: 3 },
	];

	return (
		<>
			<ModalWrapper large>
				<ModalHeader>
					<H2>View User Target</H2>
					<ModalClose onClick={closeModal}>Close</ModalClose>
				</ModalHeader>
				<ModalContent>
					<Warning message="ATTENTION: This is an historic user target, these can be converted to the new targets from within your company settings. We will alert you prior to removal of any historic targets." />
				</ModalContent>
				<ModalContent>
					<RowStyled>
						<ColStyled span={6} span768={12}>
							<SelectInput
								name="type"
								label="Target Type"
								value={target.type}
								onChange={handleChange}
								error={fieldErrors.typeError}
								required={true}
								options={UserTargetTypes}
								readonly={true}
							/>
						</ColStyled>
						<ColStyled span={6} span768={12}>
							<TextInput
								type="number"
								min={0}
								name="value"
								label="Target Value"
								value={target.value}
								onChange={handleChange}
								error={fieldErrors.valueError}
								required={true}
								readonly={true}
							/>
						</ColStyled>
					</RowStyled>
					<RowStyled>
						<ColStyled span={6} span768={12}>
							<SelectInput
								name="reportPeriod"
								label="Report Period"
								value={target.reportPeriod}
								onChange={handleChange}
								error={fieldErrors.typeError}
								required={true}
								options={reportPeriodOptions}
								help={{
									modalTitle: "Report Period",
									modalContent: <HelpModal title="Report Period" content={UserTargetReportPeriodHelpText} />,
								}}
								readonly={true}
							/>
						</ColStyled>
					</RowStyled>
					<DateRangeInput
						name="coverage"
						label="Select the date period for the target"
						note="Click on the start and end date to set the coverage period."
						onChange={(e) => setCoverage(e.target.value as Date[])}
						days={coverage}
						startMonth={new Date()}
						error={fieldErrors.startError}
						readonly={true}
					/>
					{error ? error : null}
				</ModalContent>
				<ModalFooter>
					<StandardCancelAnchor onClick={closeModal}>Cancel</StandardCancelAnchor>
				</ModalFooter>
			</ModalWrapper>
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
	setAlert,
};

export default connect(null, mapDispatchToProps)(AddTargetModal);

const getCoverage = (start: Date, end: Date) => {
	const dates = [];
	const startDate = moment(start);
	const endDate = moment(end);
	const diffInDays = endDate.diff(startDate, "days");

	for (let i = 0; i <= diffInDays; i++) {
		const currentDay = moment(start).add(i, "days").format("YYYY-MM-DD");
		dates.push(currentDay);
	}

	return dates;
};
