import React, { useState, useEffect, useRef, FormEvent, useCallback } from "react";
import { IHelp } from "../../../state/context/modals.reducers";
import { FormRow, FormRowInner, Label, SearchSelectWrapper, SearchSelect, SelectOptions, SelectOption, SelectSearchField, SelectClear, Note, ReadOnly, CanEdit } from "../../../styles/forms/inputs";
import { EventTargetType, OptionsType } from "../../../_types/form";
import InputError from "../errors/input";
import InputHelp from "./help";

type Props = {
	uncontrolled?: boolean;
	flush?: boolean;
	hideActive?: boolean;
	label?: string;
	light?: boolean;
	help?: IHelp;
	name: string;
	value?: string | number;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	options: OptionsType[];
	note?: string;
	includeSearch?: boolean;
	external?: boolean;
	labelAction?: any;
	refreshAction?: any;
	inactiveComponent?: JSX.Element;
	canEditReadOnly?: boolean;
	grow?: boolean;
	hideClear?: boolean;
	placeholder?: string;
	searchPlaceholder?: string;
};

const SelectInput: React.FC<Props> = ({
	uncontrolled,
	flush,
	hideActive,
	label,
	light,
	help,
	name,
	value,
	readonly = false,
	error = null,
	inline,
	onChange,
	required,
	options = [],
	note,
	includeSearch,
	external,
	labelAction,
	refreshAction,
	inactiveComponent,
	canEditReadOnly = null,
	grow,
	hideClear,
	placeholder,
	searchPlaceholder,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [dropActive, setDrop] = useState(false);
	const [searchSelectValue, setSearchSelectValue] = useState("");
	const [searchResults, setSearchResults] = useState(options);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const searchRef: any = useRef(null);

	const handleChange = useCallback(
		(e) => {
			if (!uncontrolled) setInputValue(e.target.value);
			onChange(e);
			setDrop(false);
		},
		[uncontrolled, onChange]
	);

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchSelectValue(value);
		const searchResults = options.filter((item) => item.Name.trim().toLowerCase().indexOf(value?.trim().toLowerCase()) > -1);
		setSearchResults(searchResults);
	};

	const clear = () => {
		if (!uncontrolled) setInputValue(null);
		onChange({ target: { name: name, value: "" } });
		toggleDrop();
	};

	useEffect(() => {
		setInputError(error);
		setSearchResults(options);
		if (value !== inputValue) {
			setInputValue(value);
			setDrop(false);
			setSearchResults(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value, options]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	const toggleDrop = () => setDrop(!dropActive);

	const handleOuterClick = useCallback((e) => {
		if (searchRef.current && !searchRef.current.contains(e.target)) {
			setDrop(false);
		}
	}, []);

	useEffect(() => {
		if (dropActive) {
			document.addEventListener("mousedown", handleOuterClick);
			return () => document.removeEventListener("mousedown", handleOuterClick);
		}
	}, [dropActive, handleOuterClick]);

	return (
		<FormRow hasError={inputError ? true : false} flush={flush}>
			<FormRowInner readonly={isReadOnly}>
				{label && (
					<Label light={light ? true : false} help={help ? true : false}>
						{label}
						{required ? "*" : null}
						{labelAction ? (
							<span>
								<a onClick={labelAction.action}>{labelAction.text}</a>
							</span>
						) : null}{" "}
						{refreshAction ? (
							<span>
								<a onClick={refreshAction.action}>{refreshAction.text}</a>
							</span>
						) : null}
						{help ? <InputHelp help={help} /> : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly}>
						{inputValue ? (options.find((item) => item.ID == inputValue) ? options.find((item) => item.ID == inputValue).Name : "N/A") : ""}
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<SearchSelectWrapper ref={searchRef}>
						<SearchSelect onClick={toggleDrop} active={dropActive} error={inputError ? true : false}>
							<span>
								{inputValue
									? options.find((item) => item.ID == inputValue)
										? options.find((item) => item.ID == inputValue).Name
										: "N/A"
									: placeholder
									? placeholder
									: "Select an option"}
							</span>
						</SearchSelect>
						{dropActive && (
							<>
								<SelectOptions grow={grow}>
									{includeSearch && (
										<SelectSearchField>
											<input type="text" name="searchSelect" value={searchSelectValue} onChange={handleSearch} placeholder={searchPlaceholder ?? "Enter search term"} />
										</SelectSearchField>
									)}
									{inputValue && !hideClear ? (
										<SelectClear>
											<a onClick={clear}>Clear</a>
										</SelectClear>
									) : null}
									{searchResults.map((item, index) =>
										item.Inactive ? (
											<SelectOption inactive key={index} external={external} hideActive={hideActive}>
												<span>{item.Name}</span>
												{inactiveComponent ? <div>{inactiveComponent}</div> : null}
											</SelectOption>
										) : (
											<SelectOption
												key={index}
												onClick={() =>
													handleChange({
														target: {
															name: name,
															value: item.ID,
															type: "select",
														},
													})
												}
												active={inputValue == item.ID ? true : false}
												external={external}
												hideActive={hideActive}
												showIcon={item.ShowIcon}
											>
												<span>{item.Name}</span>
												{item.ShowIcon ? <div>{inactiveComponent}</div> : null}
											</SelectOption>
										)
									)}
								</SelectOptions>
							</>
						)}
					</SearchSelectWrapper>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default SelectInput;
