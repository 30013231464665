import React, { useState, useEffect, FormEvent } from "react";
import { FormRow, FormRowInner, Note, ToggleWrapper, ToggleItem, ReadOnly, CanEdit } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { EventTargetType } from "../../../_types/form";
import InputHelp from "./help";
import { IHelp } from "../../../state/context/modals.reducers";

type Props = {
	label?: string;
	help?: IHelp;
	name: string;
	value: boolean;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	canEditReadOnly?: boolean;
	note?: string;
};

const CheckboxInput: React.FC<Props> = ({ label, help, name, value, readonly, error, inline, onChange, required, canEditReadOnly = null, note }) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (e: EventTargetType) => {
		(e as unknown as FormEvent<HTMLInputElement>).persist();
		const target = e.target as HTMLInputElement;
		setInputValue(target.checked);
		handleBlur(e);
	};

	const handleBlur = (e: EventTargetType) => {
		onChange(e);
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	return (
		<FormRow hasError={inputError ? true : false}>
			<FormRowInner readonly={isReadOnly}>
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly} toggle>
						<ToggleWrapper>
							<ToggleItem help={help ? true : false}>
								<input type="checkbox" name={name} value="True" id={`${name}-ReadOnly`} checked={inputValue ? true : false} disabled={true} />
								<label htmlFor={`${name}-True`}>
									{label}
									{required ? "*" : null}
								</label>
								{help ? <InputHelp help={help} /> : null}
							</ToggleItem>
						</ToggleWrapper>
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<ToggleWrapper>
						<ToggleItem help={help ? true : false}>
							<input type="checkbox" name={name} id={name} checked={inputValue} onChange={handleChange} disabled={isReadOnly ? true : false} />
							<label htmlFor={name}>{label}</label>
							{help ? <InputHelp help={help} /> : null}
						</ToggleItem>
					</ToggleWrapper>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default CheckboxInput;
