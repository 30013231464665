import React, { useState, useEffect } from "react";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import { FormRow, FormRowInner, Label, DatePickerWrapper, Note } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { EventTargetType } from "../../../_types/form";

type Props = {
	label?: string;
	light?: boolean;
	help?: string;
	days?: Date[];
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange?: (e: EventTargetType) => void;
	required?: boolean;
	includeDates?: string[];
	excludeDates?: string[];
	startMonth?: Date;
	note?: string;
	name: string;
	allowGranularSelection?: boolean;
	minimumHeight?: boolean;
};

const DateRangeInput = ({
	label,
	light,
	help,
	days = [],
	readonly,
	error,
	inline,
	onChange,
	required,
	includeDates,
	excludeDates,
	startMonth,
	note,
	name,
	allowGranularSelection,
	minimumHeight = false,
}: Props) => {
	const [inputDays, setInputDays] = useState([]);
	const [inputError, setInputError] = useState(error);
	const [includeDatesPeriod, setIncludeDatesPeriod] = useState(null);
	const [inputStartMonth, setStartMonth] = useState(startMonth);
	const handleChange = (day: any, e) => {
		let selectedDays = inputDays;

		if (!e.disabled) {
			if (allowGranularSelection) {
				const d = new Date(day).toISOString().slice(0, 10);

				if (e.selected) {
					const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(new Date(selectedDay), day));
					selectedDays.splice(selectedIndex, 1);
				} else {
					selectedDays.push(d);
				}
				setInputDays(selectedDays);
				onChange({
					target: {
						name,
						type: "array",
						value: { selectedDays: selectedDays, day: d },
					},
				});
			} else {
				if (inputDays.length >= 2) {
					setInputDays([day]);

					onChange({
						target: {
							name,
							type: "array",
							value: [day],
						},
					});
				} else {
					if (e.selected) {
						const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(new Date(selectedDay), day));
						selectedDays.splice(selectedIndex, 1);
					} else {
						selectedDays.push(day);

						if (selectedDays.length === 2) {
							selectedDays = selectedDays.sort((a, b) => a - b);

							let dateCounter = new Date(selectedDays[0]);

							while (dateCounter < selectedDays[1]) {
								selectedDays.push(new Date(dateCounter));
								dateCounter.setDate(dateCounter.getDate() + 1);
							}

							selectedDays = selectedDays.sort((a, b) => a - b);
						}
					}

					setInputDays(selectedDays);
					let filteredDays = [];
					for (const day of selectedDays) {
						if (!filteredDays.find((filteredDay: Date) => DateUtils.isSameDay(new Date(filteredDay), day))) filteredDays.push(day);
					}

					onChange({
						target: {
							name,
							type: "array",
							value: filteredDays,
						},
					});
				}
			}
		}
	};

	useEffect(() => {
		setInputError(error);
		setInputDays(days);
		if (includeDates !== includeDatesPeriod) setIncludeDatesPeriod(includeDates);
		if (startMonth !== inputStartMonth) setStartMonth(startMonth);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, days, includeDates, startMonth]);

	const isDayDisabled = (day) => {
		return (
			excludeDates?.includes?.(moment(day).format("YYYY-MM-DD")) ||
			(includeDatesPeriod && includeDatesPeriod.length
				? includeDatesPeriod.find((availableDate) => moment(day).format("YYYY-MM-DD") === moment(availableDate).format("YYYY-MM-DD")) === undefined
				: false)
		);
	};

	return (
		<FormRow>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
					</Label>
				)}
				{note ? <Note>{note}</Note> : null}
				<DatePickerWrapper minimumHeight={minimumHeight}>
					<DayPicker onDayClick={readonly ? () => {} : handleChange} selectedDays={inputDays.map((item) => new Date(item))} month={startMonth} disabledDays={isDayDisabled} />
				</DatePickerWrapper>
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default DateRangeInput;
