import React, { useState, useContext, createContext, useEffect } from "react";
import { useAuth } from "./auth.store";
import { initSocket, markAllNotificationsAsRead, markNotificationsAsClicked, reconnectSocket, rehydrate } from "../../functions/notifications";
import { Socket } from "socket.io-client";
import { GetUserInformation } from "../../functions/helpers";
import { DocumentInterface } from "../../_types/document";

export interface INotification {
	_id?: string;
	user: string;
	title: string;
	message?: string;
	action: string;
	type: NotificationTypeEnum;
	variant: NotificationTypeVariantEnum;
	expiry?: Date;
	unread?: boolean;
	unclicked?: boolean;
	data?: INotification[];
	createdAt?: Date;
	deployment?: boolean;
}

export interface IVerificationNotification {
	user: string;
	record: string;
	document?: DocumentInterface;
	type: NotificationTypeEnum;
	unread?: boolean;
	unclicked?: boolean;
}

export enum NotificationTypeEnum {
	notification = "Notification",
	notificationBatch = "NotificationBatch",
	notificationAll = "NotificationAll",
	notificationMarkAsRead = "NotificationMarkAsRead",
	notificationGlobalMarkAsRead = "NotificationGlobalMarkAsRead",
	notificationAsClicked = "NotificationAsClicked",
	notificationRehydrate = "NotificationRehydrate",
	global = "Global",
	verification = "Verification",
}

export enum NotificationTypeVariantEnum {
	assignee = "assignee",
	booking = "booking",
	candidate = "candidate",
	comment = "comment",
	timesheet = "timesheet",
	global = "global",
	placement = "placement",
	error = "error",
}

export interface GlobalNotificationsState {
	socket: Socket;
	setSocket: (socket: Socket) => void;
	notifications: INotification[];
	notificationsAll: INotification[];
	setNotifications: (notifications: INotification[]) => void;
	setNotificationsAll: (notifications: INotification[]) => void;
	updateNotifications: (notification: INotification) => void;
	markAllAsRead: (user: string) => void;
	markGlobalAsRead: (user: string) => void;
	markAsClicked: (user: string, notificationId: string) => void;
	notificationsCount: number;
	setDisconnected: (disconnected: boolean) => void;
	disconnected: boolean;
	globalNotification: INotification[];
	setGlobalNotification: (notification: INotification[]) => void;
	recordUpdate: IVerificationNotification;
	setRecordUpdate: (recordUpdate: IVerificationNotification) => void;
}

export const initialNotificationsState: GlobalNotificationsState = {
	socket: null,
	setSocket: () => {},
	notifications: [],
	notificationsAll: [],
	setNotifications: () => {},
	setNotificationsAll: () => {},
	updateNotifications: () => {},
	markAllAsRead: () => {},
	markGlobalAsRead: () => {},
	markAsClicked: () => {},
	notificationsCount: 0,
	setDisconnected: () => {},
	disconnected: false,
	globalNotification: null,
	setGlobalNotification: () => {},
	recordUpdate: null,
	setRecordUpdate: () => {},
};

const NotificationsStoreContext = createContext<GlobalNotificationsState>(initialNotificationsState);

type Props = {
	children: React.ReactChild;
};

export const NotificationsStoreProvider = ({ children }: Props) => {
	const { auth } = useAuth();
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [notificationsAll, setNotificationsAll] = useState<INotification[]>([]);
	const [notificationsCount, setNotificationsCount] = useState<number>(0);
	const [socket, setSocket] = useState<Socket>(null);
	const [disconnected, setDisconnected] = useState<boolean>(false);
	const [globalNotification, setGlobalNotification] = useState<INotification[]>([]);
	const [recordUpdate, setRecordUpdate] = useState<IVerificationNotification>(null);

	useEffect(() => {
		if (auth.token) {
			initSocket(auth.token, setSocket, setDisconnected, updateNotifications, setNotifications, setNotificationsAll, setRecordUpdate);
			// setSocket(socketConnection);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.token]);

	useEffect(() => {
		rehydrateNotifications();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setNotificationsCount(notifications.filter((notification) => notification.unread).length);
		storeNotifications(notifications);

		setGlobalNotification(notifications.filter((notification) => notification.variant === NotificationTypeVariantEnum.global && notification.unread));
	}, [notifications]);

	useEffect(() => {
		// Listen for visibility change events
		document.addEventListener("visibilitychange", handleVisibilityChange, false);

		// Clean up event listener on unmount
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange, false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleVisibilityChange = () => {
		if (!document?.hidden) {
			reconnectSocket();
		}
	};

	const updateNotifications = (notification: INotification) => {
		setNotifications((prevNotifications) => {
			// storeNotifications([...[notification], ...prevNotifications]);
			return [...[notification], ...prevNotifications];
		});
		setNotificationsAll((prevNotifications) => [...[notification], ...prevNotifications]);
	};

	const markAllAsRead = (user: string) => {
		markAllNotificationsAsRead(user);
		setNotifications((prevNotifications) => {
			return prevNotifications.map((notification) => ({ ...notification, ...{ unread: false } }));
		});
	};

	const markGlobalAsRead = (user: string) => {
		// markGlobalNotificationsAsRead(user);
		setNotifications((prevNotifications) => {
			return prevNotifications.map((notification) => (notification.variant === NotificationTypeVariantEnum.global ? { ...notification, ...{ unread: false, unclicked: false } } : notification));
		});
	};

	const markAsClicked = (user: string, notificationId: string) => {
		markNotificationsAsClicked(user, notificationId);
		setNotifications((prevNotifications) => {
			const updatedNotifications = prevNotifications.map((notification) => {
				if (notification._id === notificationId) {
					return { ...notification, ...{ unclicked: false } };
				}
				return notification;
			});
			// storeNotifications(updatedNotifications);
			return updatedNotifications;
		});
		setNotificationsAll((prevNotifications) => {
			const updatedNotifications = prevNotifications.map((notification) => {
				if (notification._id === notificationId) {
					return { ...notification, ...{ unclicked: false } };
				}
				return notification;
			});
			return updatedNotifications;
		});
	};

	const storeNotifications = (updatedNotifications) => {
		sessionStorage.setItem("notifications", JSON.stringify(updatedNotifications));
	};

	const rehydrateNotifications = () => {
		const savedNotifications = JSON.parse(sessionStorage.getItem("notifications")) || [];
		if (savedNotifications?.length) {
			setNotifications(savedNotifications);
		} else {
			const decodedToken = GetUserInformation(auth.token);
			rehydrate(decodedToken?.userId);
		}
	};

	return (
		<NotificationsStoreContext.Provider
			value={{
				socket,
				setSocket,
				notifications,
				setNotifications,
				updateNotifications,
				notificationsCount,
				markAllAsRead,
				markGlobalAsRead,
				markAsClicked,
				setNotificationsAll,
				notificationsAll,
				setDisconnected,
				disconnected,
				setGlobalNotification,
				globalNotification,
				recordUpdate,
				setRecordUpdate,
			}}
		>
			{children}
		</NotificationsStoreContext.Provider>
	);
};

export const useNotifications = () => useContext(NotificationsStoreContext);
