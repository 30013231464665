const API_URL = process.env.API_URL;

export const DOCS_URL = process.env.DOCS_URL;

export const NOTIFICATION_SERVICE = process.env.NOTIFICATION_SERVICE;
export const NOTIFICATION_SERVICE_KEY = process.env.NOTIFICATION_SERVICE_KEY;

const AUTOMATION_API_URL = process.env.AUTOMATION_API_URL;

export const CHECK_AUTH_SESSION = `${API_URL}account/session`;

export const CHECK_PRE_AUTH_SESSION = `${API_URL}account/preauthsession`;

export const REMOVE_AUTH_SESSION = `${API_URL}account/logout`;

export const LOGIN_PROXY = `/api/login`;

export const LOGIN = `${API_URL}account/login`;

export const CODE_LOGIN_PROXY = `/api/validate`;

export const CODE_LOGIN = `${API_URL}account/login/code`;

export const COMPLETE_LOGIN = `${API_URL}account/completeLogin`;

export const ACTIVATE_ACCOUNT_1 = `${API_URL}user/activate`;

export const ACTIVATE_ACCOUNT_2 = `${API_URL}user/activateComplete`;

export const RESET_PASSWORD_1 = `${API_URL}account/validate-email`;

export const RESET_PASSWORD_2 = `${API_URL}account/reset-password`;

export const REGISTER = `${API_URL}register`;

export const CREATE_CLIENT = `${API_URL}client`;

export const UPDATE_CLIENT = (CLIENT_ID) => `${API_URL}client/${CLIENT_ID}`;

export const UPDATE_CLIENT_CONTACTS = (CLIENT_ID) => `${API_URL}client/contacts/${CLIENT_ID}`;

export const UPDATE_CLIENT_ID = (CLIENT_ID) => `${API_URL}client/customid/${CLIENT_ID}`;

export const UPDATE_CLIENT_INTERNAL_ID = (CLIENT_ID) => `${API_URL}client/internalid/${CLIENT_ID}`;

export const CREATE_CLIENTS = `${API_URL}clients`;

export const GET_CLIENTS = `${API_URL}clients/find`;

export const EXPORT_CLIENTS = (CONTACT_TYPE) => `${API_URL}clients/export/${CONTACT_TYPE}`;

export const EXPORT_CONTACTS = `${API_URL}clients/contacts/export`;

export const EXPORT_CLIENT = (CLIENT_ID, CONTACT_TYPE) => `${API_URL}clients/export/${CLIENT_ID}/${CONTACT_TYPE}`;

export const SEND_TERMS_OF_BUSINESS = (CLIENT_ID) => `${API_URL}clients/tob/${CLIENT_ID}`;

export const SEND_CLIENT_EMAIL = (CLIENT_ID) => `${API_URL}client/email/send/${CLIENT_ID}`;

export const GET_CLIENTS_STUB = `${API_URL}clientsStub`;

export const GET_CLIENTS_BOOKING_STUB = `${API_URL}clientsStub/booking`;

export const GET_CLIENTS_BOOKING_STUB_LATEST = `${API_URL}clientsStub/booking/latest`;

export const GET_CLIENTS_BOOKING_STUB_SEARCH = `${API_URL}clientsStub/booking/search`;

export const GET_CLIENTS_IDS = `${API_URL}clients/find/ids`;

export const GET_CLIENT = (CLIENT_ID) => `${API_URL}client/${CLIENT_ID}`;

export const GET_CLIENT_SUMMARY = (CLIENT_ID) => `${API_URL}client/summary/${CLIENT_ID}`;

export const UPDATE_CLIENT_ASSIGNEE = (CLIENT_ID) => `${API_URL}client/assignee/${CLIENT_ID}`;

export const MARKETING_RECIPIENT_SEARCH = `${API_URL}clients/contacts/search`;

export const MARKETING_RECIPIENT_SEARCH_ALL = `${API_URL}clients/contacts/searchall`;

export const MARKETING_RECIPIENT_CANDIDATE_SEARCH = `${API_URL}candidate/contacts/search`;

export const MARKETING_RECIPIENT_CANDIDATE_SEARCH_MOVE = `${API_URL}candidate/contacts/search/move`;

export const CANDIDATES_REQUEST_AVAILABILITY_RESEND_SEARCH = `${API_URL}candidate/contacts/search/availability/resend`;

export const GET_CONTACTS_BY_ROLE = (ROLE) => `${API_URL}clients/contacts/${ROLE}`;

export const GET_CONTACTS_BY_NAME = `${API_URL}clients/contacts/name`;

export const GET_CONTACTS_BY_EMAIL = `${API_URL}clients/contacts/email`;

export const GET_CONTACTS_BY_POSTCODE = `${API_URL}clients/contacts/postcode`;

export const CREATE_CANDIDATE = `${API_URL}candidate`;

export const UPDATE_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/${CANDIDATE_ID}`;

export const UPDATE_CANDIDATE_STATUS = (CANDIDATE_ID) => `${API_URL}candidate/${CANDIDATE_ID}/status`;

export const UPDATE_CANDIDATE_ID = (CANDIDATE_ID) => `${API_URL}candidate/customid/${CANDIDATE_ID}`;

export const UPDATE_CANDIDATE_INTERNAL_ID = (CANDIDATE_ID) => `${API_URL}candidate/internalid/${CANDIDATE_ID}`;

export const ARCHIVE_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/archive/${CANDIDATE_ID}`;

export const ANONYMISE_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/anonymise/${CANDIDATE_ID}`;

export const UPLOAD_CANDIDATE_CV = `${API_URL}candidate/parse`;

export const PARSE_EXISTING_CANDIDATE_CV = `${API_URL}candidate/parseExistingCandidate`;

export const EXPORT_CANDIDATES = `${API_URL}candidates/export`;

export const EXPORT_PROCESSING_CANDIDATES = `${API_URL}candidates/exportProcessing`;

export const EXPORT_BOOKED_CANDIDATES = `${API_URL}candidates/exportBooked`;

export const EXPORT_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/export/${CANDIDATE_ID}`;

export const EXPORT_CANDIDATE_VETTING = (CANDIDATE_ID) => `${API_URL}candidate/export/vetting/${CANDIDATE_ID}`;

export const EXPORT_CANDIDATE_VETTING_WORD = (CANDIDATE_ID) => `${API_URL}candidate/export/vetting/word/${CANDIDATE_ID}`;

export const EXPORT_EDUN_CV = (CANDIDATE_ID) => `${API_URL}candidate/export/cv/${CANDIDATE_ID}`;

export const EXPORT_EDUN_CV_WORD = (CANDIDATE_ID) => `${API_URL}candidate/export/cv/word/${CANDIDATE_ID}`;

export const EXPORT_EDUN_CV_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/export/cv/${CANDIDATE_ID}/${TOKEN}`;

export const EXPORT_REFERENCE_SUBMISSION = (REFERENCE_SUBMISSION_ID) => `${API_URL}candidate/export/reference/${REFERENCE_SUBMISSION_ID}`;

export const EXPORT_SAFEGUARDING_QUESTIONAIRE = (CANDIDATE_ID) => `${API_URL}candidate/export/safeguardingquestionaire/${CANDIDATE_ID}`;

export const UPDATE_CANDIDATE_PROFILE_PHOTO = (CANDIDATE_ID) => `${API_URL}candidate/profileProfile/${CANDIDATE_ID}`;

export const UPDATE_CANDIDATE_PROFILE_PHOTO_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/profileProfileOnboard/${CANDIDATE_ID}/${TOKEN}`;

export const VERIFY_CANDIDATE_PROFILE_PHOTO_ONBOARD = (TOKEN) => `${API_URL}document/verify/candidate/profile/liveness/${TOKEN}`;

export const UPDATE_CANDIDATE_ASSIGNEE = (CANDIDATE_ID) => `${API_URL}candidate/assignee/${CANDIDATE_ID}`;

export const UPDATE_CANDIDATE_COMPLIANCE_USER = (CANDIDATE_ID) => `${API_URL}candidate/complianceUser/${CANDIDATE_ID}`;

export const CREATE_CANDIDATES = `${API_URL}candidates`;

export const UPDATE_CANDIDATE_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/onboard/${CANDIDATE_ID}/${TOKEN}`;

export const UPDATE_CANDIDATE_MARKETING_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/onboard/marketing/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CANDIDATES = `${API_URL}candidates/find`;

export const GET_CANDIDATE_APPLICANTS = `${API_URL}candidates/applicants/find`;

export const GET_CANDIDATES_PROCESSING = `${API_URL}candidates/processing/find`;

export const GET_CANDIDATES_BOOKED = `${API_URL}candidates/booked/find`;

export const GET_CANDIDATES_STUB = (ACTIVE_ONLY) => `${API_URL}candidates/stub/find${ACTIVE_ONLY ? "/active" : ""}`;

export const GET_CANDIDATES_FREE_STUB = (BOOKING_TYPE) => `${API_URL}candidates/stub/find/free/${BOOKING_TYPE}`;

export const GET_CANDIDATES_FREE_STUB_LATEST = (BOOKING_TYPE) => `${API_URL}candidates/stub/find/free/latest/${BOOKING_TYPE}`;

export const GET_CANDIDATES_FREE_STUB_SEARCH = (BOOKING_TYPE) => `${API_URL}candidates/stub/find/free/search/${BOOKING_TYPE}`;

export const GET_CANDIDATES_ALL_STUB_SEARCH = `${API_URL}candidates/stub/find/all/search`;

export const GET_STUDENTS_FREE_STUB = `${API_URL}students/stub/find/active`;

export const GET_STUDENTS_STUB_LATEST = `${API_URL}students/stub/find/latest`;

export const GET_STUDENTS_STUB_SEARCH = `${API_URL}students/stub/find/search`;

export const GET_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/${CANDIDATE_ID}`;

export const GET_CANDIDATE_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/external/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CANDIDATE_SUMMARY = (CANDIDATE_ID) => `${API_URL}candidate/summary/${CANDIDATE_ID}`;

export const GET_CANDIDATE_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/onboard/${CANDIDATE_ID}/${TOKEN}`;

export const UPLOAD_DOCUMENT = (UPLOAD_TYPE, USER_ID, DOCUMENT_TYPE) => `${API_URL}document/${UPLOAD_TYPE}/${USER_ID}/${DOCUMENT_TYPE}`;

export const UPLOAD_CLIENT_DOCUMENT = (UPLOAD_TYPE, USER_ID, DOCUMENT_TYPE) => `${API_URL}documentClient/${UPLOAD_TYPE}/${USER_ID}/${DOCUMENT_TYPE}`;

export const UPLOAD_DOCUMENT_ONBOARD = (UPLOAD_TYPE, USER_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/onboard/${UPLOAD_TYPE}/${USER_ID}/${DOCUMENT_TYPE}/${TOKEN}`;

export const UPLOAD_DOCUMENT_REQUESTED = (UPLOAD_TYPE, USER_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/requested/${UPLOAD_TYPE}/${USER_ID}/${DOCUMENT_TYPE}/${TOKEN}`;

export const GET_CANDIDATE_DOCUMENTS = (CANDIDATE_ID, DOCUMENT_TYPE) => `${API_URL}document/${CANDIDATE_ID}/${DOCUMENT_TYPE}`;

export const GET_COMPANY_DOCUMENTS = (DOCUMENT_LEVEL_TYPE) => `${API_URL}document/type/company/${DOCUMENT_LEVEL_TYPE}`;

export const GET_COMPANY_DOCUMENTS_ALL = `${API_URL}document/company`;

export const GET_COMPANY_BOOKING_ATTACHMENTS = `${API_URL}document/attachments`;

export const GET_CANDIDATE_DOCUMENTS_ONBOARD = (CANDIDATE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/onboard/${CANDIDATE_ID}/${DOCUMENT_TYPE}/${TOKEN}`;

export const GET_CANDIDATE_DOCUMENTS_REQUESTED = (CANDIDATE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/requested/${CANDIDATE_ID}/${DOCUMENT_TYPE}/${TOKEN}`;

export const GET_CANDIDATE_DOCUMENT = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE) => `${API_URL}document/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}`;

export const GET_DOCUMENT = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE) => `${API_URL}document/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}`;

export const VERIFY_CANDIDATE_DOCUMENT = (DOCUMENT_REFERENCE_ID) => `${API_URL}document/verify/candidate/${DOCUMENT_REFERENCE_ID}`;

export const VERIFY_CANDIDATE_PROFILE_PHOTO = `${API_URL}document/verify/candidate/profile/liveness`;

export const EXPORT_DOCUMENT_VERIFICATION = (DOCUMENT_VERIFICATION_ID) => `${API_URL}document-verifications/${DOCUMENT_VERIFICATION_ID}`;

export const DOWNLOAD_DOCUMENT_ONBOARD = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const DOWNLOAD_DOCUMENT_TIMESHEET = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/timesheet/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const DOWNLOAD_DOCUMENT_TIMESHEET_CANDIDATE = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) =>
	`${API_URL}document/timesheet/candidate/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const DOWNLOAD_DOCUMENT_EXTERNAL = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/external/candidate/download/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const DELETE_CANDIDATE_DOCUMENT = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE) => `${API_URL}document/delete/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}`;

export const DELETE_CANDIDATE_DOCUMENT_ONBOARD = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/onboard/delete/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const DELETE_CANDIDATE_DOCUMENT_REQUESTED = (DOCUMENT_REFERENCE_ID, DOCUMENT_TYPE, TOKEN) => `${API_URL}document/requested/delete/${DOCUMENT_TYPE}/${DOCUMENT_REFERENCE_ID}/${TOKEN}`;

export const SEND_CANDIDATE_ONBOARDING_FORM = (CANDIDATE_ID) => `${API_URL}candidate/onboard/${CANDIDATE_ID}`;

export const REQUEST_BANK_DETAILS = `${API_URL}candidate/bank/request`;

export const BANK_DETAILS_REQUEST_SUBMITTED = `${API_URL}candidate/bank/request`;

export const SEND_REFERENCE_REQUEST = `${API_URL}referenceSubmission`;

export const CANCEL_REFERENCE_REQUEST = (REFERENCE_ID) => `${API_URL}referenceSubmission/cancel/${REFERENCE_ID}`;

export const MARK_REFERENCE_COMPLETE = `${API_URL}referenceSubmission/complete`;

export const UPDATE_REFERENCE_SUBMISSION_APPROVAL = (REFERENCE_ID) => `${API_URL}referenceSubmission/complete/approval/${REFERENCE_ID}`;

export const GET_REFERENCE_SUBMISSION = (REFERENCE_ID) => `${API_URL}referenceSubmission/${REFERENCE_ID}`;

export const GET_REFERENCE_SUBMISSION_NOAUTH = (REFERENCE_ID, TOKEN) => `${API_URL}referenceSubmission/${REFERENCE_ID}/${TOKEN}`;

export const GET_REFERENCE_SUBMISSION_ONBOARDING = (REFERENCE_ID, TOKEN) => `${API_URL}onboarding/referenceSubmission/${REFERENCE_ID}/${TOKEN}`;

export const UPDATE_REFERENCE_SUBMISSION = (REFERENCE_ID, TOKEN) => `${API_URL}referenceSubmission/${REFERENCE_ID}/${TOKEN}`;

export const GET_REFERENCE_SEND_STATUS = (CANDIDATE_ID) => `${API_URL}referenceTrigger/sendStatus/${CANDIDATE_ID}`;

export const CREATE_BOOKING = `${API_URL}booking`;

export const UPDATE_BOOKING = (BOOKING_ID) => `${API_URL}booking/${BOOKING_ID}`;

export const CONVERT_DRAFT_BOOKING = (BOOKING_ID) => `${API_URL}booking/draft/${BOOKING_ID}`;

export const CANCEL_FUTURE_BOOKING = (BOOKING_ID) => `${API_URL}booking/cancel/future/${BOOKING_ID}`;

export const RESEND_BOOKING_CONFIRMATION = (TYPE, BOOKING_ID) => `${API_URL}booking/confirmation/${BOOKING_ID}/${TYPE}`;

export const UPDATE_BOOKING_ASSIGNEE = (BOOKING_ID) => `${API_URL}booking/assignee/${BOOKING_ID}`;

export const GET_BOOKINGS_FILTERED = `${API_URL}bookings/filtered`;

export const EXPORT_BOOKINGS_FILTERED = `${API_URL}bookings/export`;

export const EXPORT_BOOKINGS_FINANCIALS = `${API_URL}bookings/export/financials`;

export const GET_BOOKINGS_STUBS_BY_CLIENT = (CLIENT_ID) => `${API_URL}booking/client/stubs/${CLIENT_ID}`;

export const GET_BOOKINGS_BY_CANDIDATE = (CANDIDATE_ID) => `${API_URL}booking/candidate/${CANDIDATE_ID}`;

export const GET_BOOKINGS_BY_CANDIDATE_ALL = (CANDIDATE_ID) => `${API_URL}booking/candidate/${CANDIDATE_ID}/all`;

export const GET_BOOKINGS_BY_CLIENT = (CLIENT_ID) => `${API_URL}booking/client/${CLIENT_ID}`;

export const GET_BOOKINGS_FILTERED_BY_CANDIDATE = (CANDIDATE_ID) => `${API_URL}booking/candidate/filtered/${CANDIDATE_ID}`;

export const CHECK_FOR_CANDIDATE_CONDITIONS = (CANDIDATE_ID) => `${API_URL}booking/candidate/conditions/${CANDIDATE_ID}`;

export const GET_BOOKING = (BOOKING_ID) => `${API_URL}booking/${BOOKING_ID}`;

export const GET_DRAFT_BOOKINGS_COUNT = `${API_URL}booking/drafts/count`;

export const GET_DRAFT_BOOKINGS_WEEKLY_COUNT = `${API_URL}booking/drafts/weekly/count`;

export const GET_DRAFT_BOOKING = (BOOKING_ID) => `${API_URL}booking/draft/${BOOKING_ID}`;

export const GET_BOOKING_WSOP = `${API_URL}booking/wsop/settings`;

export const EXPORT_BOOKING_WSOP = (BOOKING_ID) => `${API_URL}booking/wsop/${BOOKING_ID}`;

export const GET_BOOKING_EXTERNAL = (BOOKING_ID) => `${API_URL}booking/external/${BOOKING_ID}`;

export const UPDATE_BOOKING_ATTENDANCE = (BOOKING_ID) => `${API_URL}booking/external/attendance/${BOOKING_ID}`;

export const ACKNOWLEDGE_BOOKING = (BOOKING_ID, CANDIDATE_ID) => `${API_URL}booking/external/acknowledge/${BOOKING_ID}/${CANDIDATE_ID}`;

export const PREVIEW_BOOKING_CONFIRMATION = (CONFIRMATION_TYPE) => `${API_URL}booking/preview/${CONFIRMATION_TYPE}`;

export const SYNC_BOOKING_DATA = `${API_URL}booking/data/sync`;

export const APPLY_SYNC_BOOKING_DATA = `${API_URL}booking/data/sync/confirm`;

export const CREATE_TIMESHEETS = `${API_URL}timesheets`;

export const RESEND_TIMESHEETS_BY_GROUP = (GROUP_ID) => `${API_URL}timesheets/resend/group/${GROUP_ID}`;

export const GET_TIMESHEET_STUBS_BY_CLIENT = (CLIENT_ID) => `${API_URL}timesheets/client/stubs/${CLIENT_ID}`;

export const UPDATE_TIMESHEET = (TIMESHEET_ID) => `${API_URL}timesheet/${TIMESHEET_ID}`;

export const DELETE_TIMESHEET = (TIMESHEET_ID) => `${API_URL}timesheet/${TIMESHEET_ID}`;

export const UPDATE_TIMESHEET_ASSIGNEE = (TIMESHEET_ID) => `${API_URL}timesheet/assignee/${TIMESHEET_ID}`;

export const RESEND_TIMESHEET_APPROVAL = (TIMESHEET_ID, TYPE) => `${API_URL}timesheet/resend/${TIMESHEET_ID}/${TYPE}`;

export const GET_TIMESHEETS = `${API_URL}timesheets/find`;

export const EXPORT_TIMESHEETS = `${API_URL}timesheets/export`;

export const EXPORT_TIMESHEETS_PDF = `${API_URL}timesheets/zip/export`;

export const RESEND_TIMESHEETS_BULK = `${API_URL}timesheets/resend/bulk`;

export const EXPORT_TIMESHEET = (TIMESHEET_ID) => `${API_URL}timesheets/export/${TIMESHEET_ID}`;

export const EXPORT_TIMESHEET_PDF = (TIMESHEET_ID) => `${API_URL}timesheets/pdf/${TIMESHEET_ID}`;

export const EXPORT_TUITION_REPORT_PDF = (TIMESHEET_ID) => `${API_URL}timesheets/tuition-report/pdf/${TIMESHEET_ID}`;

export const EXPORT_TUITION_REPORT_PDF_EXTERNAL = (TOKEN, TIMESHEET_ID) => `${API_URL}timesheets/tuition-report/pdf/${TOKEN}/${TIMESHEET_ID}`;

export const GET_TIMESHEET = (TIMESHEET_ID) => `${API_URL}timesheet/${TIMESHEET_ID}`;

export const GET_TIMESHEETS_BY_GROUP = (GROUP_ID) => `${API_URL}timesheet/group/timesheets/${GROUP_ID}`;

export const VALIDATE_TIMESHEET = (TIMESHEET_ID, TOKEN) => `${API_URL}timesheet/${TIMESHEET_ID}/${TOKEN}`;

export const VALIDATE_TIMESHEET_CANDIDATE = (TIMESHEET_ID, TOKEN) => `${API_URL}timesheet/user/${TIMESHEET_ID}/${TOKEN}`;

export const GET_TIMESHEET_FOR_APPROVAL = (TIMESHEET_ID, TOKEN) => `${API_URL}timesheet/${TIMESHEET_ID}/${TOKEN}`;

export const GET_TIMESHEETS_FOR_APPROVAL = (GROUP_ID, TOKEN) => `${API_URL}timesheet/group/${GROUP_ID}/${TOKEN}`;

export const CREATE_TIMESHEET_QUERY = `${API_URL}timesheetQuery`;

export const GET_TIMESHEET_QUERY = (TIMESHEET_ID) => `${API_URL}timesheetQuery/${TIMESHEET_ID}`;

export const ADD_TIMESHEET_COMMENT = (TIMESHEET_ID) => `${API_URL}timesheetQuery/comment/${TIMESHEET_ID}`;

export const GET_SINGLE_SENDS = `${API_URL}marketing/singleSends`;

export const GET_SCHEDULED_SINGLE_SENDS = `${API_URL}marketing/singleSends/scheduled`;

export const GET_MARKETING_SUPPRESSIONS = (SUPRESSION_TYPE) => `${API_URL}marketing/suppressions/${SUPRESSION_TYPE}`;

export const GET_SINGLE_SENDS_TEMPLATES = `${API_URL}marketing/singleSends/templates`;

export const GET_SINGLE_SENDS_TEMPLATES_STUB = `${API_URL}marketing/singleSends/templates/stub`;

export const CREATE_TEMPLATE = `${API_URL}marketing/singleSends/template`;

export const UPDATE_TEMPLATE = (TEMPLATE_ID) => `${API_URL}marketing/singleSends/template/${TEMPLATE_ID}`;

export const VALIDATE_TEMPLATE = `${API_URL}marketing/singleSends/template/validate`;

export const CHECK_RECIPIENTS = `${API_URL}marketing/recipients`;

export const GET_TEMPLATE = (TEMPLATE_ID) => `${API_URL}marketing/singleSends/template/${TEMPLATE_ID}`;

export const PREVIEW_TEMPLATE = `${API_URL}marketing/singleSends/template/preview`;

export const CREATE_SINGLE_SEND = `${API_URL}marketing/singleSends/singleSend`;

export const CANCEL_SINGLE_SEND = (SINGLE_SEND_ID) => `${API_URL}marketing/singleSends/cancel/${SINGLE_SEND_ID}`;

export const UPDATE_SINGLE_SEND_SCHEDULE = (SINGLE_SEND_ID) => `${API_URL}marketing/singleSends/schedule/${SINGLE_SEND_ID}`;

export const CREATE_MAILER_SINGLE_SEND = `${API_URL}marketing/singleSends/mailer/singleSend`;

export const GET_SINGLE_SEND = (SINGLE_SEND_ID) => `${API_URL}marketing/singleSends/singleSend/${SINGLE_SEND_ID}`;

export const GET_SINGLE_SEND_STATS = `${API_URL}marketing/singleSends/singleSend/stats`;

export const GET_SINGLE_SEND_STATS_DETAIL = (CATEGORY_ID) => `${API_URL}marketing/singleSends/singleSend/stats/events/${CATEGORY_ID}`;

export const CREATE_USER = `${API_URL}user`;

export const UPDATE_USER = (USER_ID) => `${API_URL}user/${USER_ID}`;

export const UPDATE_USER_COMMISSION = (USER_ID) => `${API_URL}user/commission/${USER_ID}`;

export const UPDATE_LINKED_CLIENTS = (USER_ID) => `${API_URL}user/clients/${USER_ID}`;

export const TOGGLE_ACCOUNT_STATE = (USER_ID) => `${API_URL}user/accountState/${USER_ID}`;

export const DELETE_USER = (USER_ID) => `${API_URL}user/${USER_ID}`;

export const UPLOAD_IMAGE = (UPLOAD_TYPE) => `${API_URL}image/${UPLOAD_TYPE}`;

export const UPLOAD_IMAGE_ONBOARD = (UPLOAD_TYPE, TOKEN) => `${API_URL}image/onboarding/${UPLOAD_TYPE}/${TOKEN}`;

export const GENERIC_DOCUMENT = (CONTAINER) => `${API_URL}document/generic/${CONTAINER}`;

export const REQUEST_DOCUMENTS = `${API_URL}document/request`;

export const DOCUMENT_REQUEST_SUBMITTED = (TOKEN) => `${API_URL}document/request/submitted/${TOKEN}`;

export const GET_CANDIDATE_FOR_DOCUMENTS_REQUESTED = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/document/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CANDIDATE_BANK_REQUESTED = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/bank/${CANDIDATE_ID}/${TOKEN}`;

export const GET_EXTERNAL_REFERENCE_SESSION = (TYPE, REFERENCE_ID) => `${API_URL}candidate/reference/session/${TYPE}/${REFERENCE_ID}`;

export const CANDIDATE_BANK_REQUEST_SUBMITTED = (TOKEN) => `${API_URL}candidate/bank/request/submitted/${TOKEN}`;

export const UPDATE_CUSTOMER = (CUSTOMER_ID) => `${API_URL}customer/${CUSTOMER_ID}`;

export const UPDATE_CUSTOMER_EXTERNAL_COMMS = `${API_URL}customer/externalComms/update`;

export const GET_CUSTOMER_BRANDING = `${API_URL}customer/branding`;

export const GET_CUSTOMER_BRANDING_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}customer/branding/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CUSTOMER_BRANDING_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}customer/branding/external/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CUSTOMER_BRANDING_DOCUMENT = (CANDIDATE_ID, TOKEN) => `${API_URL}customer/branding/document/${CANDIDATE_ID}/${TOKEN}`;

export const GET_CUSTOMER_CREDITS = `${API_URL}credits`;

export const GET_ALL_CUSTOMER_CREDITS = `${API_URL}credits/all`;

export const GET_SUBSCRIPTION_USERS = `${API_URL}users`;

export const GET_SUBSCRIPTION_USERS_STUB = `${API_URL}users/stub`;

export const GET_SUBSCRIPTION_USERS_STUB_ALL = `${API_URL}users/stub/all`;

export const GET_TARGET_USERS = `${API_URL}users/targets`;

export const GET_USER = (USER_ID) => `${API_URL}user/${USER_ID}`;

export const GET_USER_CLIENTS = (USER_ID) => `${API_URL}user/clients/${USER_ID}`;

export const GET_USER_AUTH_SESSION = (USER_ID) => `${API_URL}user/active-session/${USER_ID}`;

export const RESEND_ACTIVATION_EMAIL = `${API_URL}user/resendActivationEmail`;

export const GET_MARKETING_SETTINGS = `${API_URL}marketing/settings`;

export const GET_COMPANY_MARKETING_SETTINGS = `${API_URL}marketing/company/settings`;

export const UPDATE_MARKETING_SETTINGS = `${API_URL}marketing/settings`;

export const GET_USER_MARKETING_SETTINGS = `${API_URL}marketing/user/settings`;

export const HIDE_ONBOARD = (USER_ID) => `${API_URL}user/onboarding/${USER_ID}`;

export const NOTIFICATION_PREFERENCES = `${API_URL}customer/preferences/notificationPreferences`;

// Remarks
export const CREATE_REMARK = `${API_URL}remark`;

export const DELETE_REMARK = (REMARK_ID) => `${API_URL}remark/${REMARK_ID}`;

export const UPDATE_REMARK = (REMARK_ID) => `${API_URL}remark/${REMARK_ID}`;

export const UPDATE_REMARK_PIN = (REMARK_ID) => `${API_URL}remark/pin/toggle/${REMARK_ID}`;

export const UPDATE_REMARK_FLAG = (REMARK_ID) => `${API_URL}remark/flag/toggle/${REMARK_ID}`;

export const GET_REMARKS = (TYPE, REFERENCE_ID) => `${API_URL}remark/${TYPE}/${REFERENCE_ID}`;

export const GET_REMARKS_PIN_TOTAL = (TYPE, REFERENCE_ID) => `${API_URL}remark/pintotal/${TYPE}/${REFERENCE_ID}`;

// Booking costs
export const BOOKING_COSTS = `${API_URL}bookingCosts`;

export const BOOKING_RATES = `${API_URL}bookingRates`;

export const CHECK_BOOKING_COSTS = `${API_URL}bookingCosts/validate`;

export const CHECK_BOOKING_RATES = `${API_URL}bookingCosts/rates/validate`;

export const BOOKING_COSTS_HOLIDAY = `${API_URL}bookingCosts/holiday`;

// Contract for service
export const CONTRACT_FOR_SERVICE = `${API_URL}contractForService`;

export const CONTRACT_FOR_SERVICE_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}contractForService/${CANDIDATE_ID}/${TOKEN}`;

export const TERMS_OF_BUSINESS = `${API_URL}termsOfBusiness`;

export const TERMS_OF_BUSINESS_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}termsOfBusiness/${CANDIDATE_ID}/${TOKEN}`;

// Privacy Policy
export const PRIVACY_POLICY = `${API_URL}privacyPolicy`;

export const PRIVACY_POLICY_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}privacyPolicy/${CANDIDATE_ID}/${TOKEN}`;

// Integrations
export const XERO_CONNECT = `${API_URL}xero/connect`;

export const XERO_AUTHENTICATE = `${API_URL}xero/authenticate`;

export const XERO_REFRESH = `${API_URL}xero/refresh`;

export const XERO_INVOICES = `${API_URL}xero/invoices`;

export const XERO_INVOICE = (INVOICE_ID) => `${API_URL}xero/invoices/${INVOICE_ID}`;

export const XERO_SEND_INVOICE = (INVOICE_ID) => `${API_URL}xero/invoices/send/${INVOICE_ID}`;

export const XERO_INVOICE_CONTACTS = `${API_URL}xero/contacts`;

export const XERO_INVOICE_ACCOUNTS = `${API_URL}xero/accounts`;

export const XERO_INVOICE_CREATE_CONTACT = `${API_URL}xero/contacts/create`;

export const XERO_INVOICE_CREATE = `${API_URL}xero/invoices/create`;

export const XERO_INVOICE_EXPORTS = `${API_URL}xero/invoices/export`;

export const XERO_GET_TRACKING_CATEGORIES = `${API_URL}xero/trackingCategories`;

export const PAYMENT_RUNS = `${API_URL}payments/payment-runs`;

export const GET_CANDIDATE_CHARGEABLE_DAYS = `${API_URL}payments/payment-runs/chargeable-days`;

export const GET_CANDIDATE_PERM_CHARGEABLE_DAYS = `${API_URL}payments/payment-runs/chargeable-days/perm`;

export const CREATE_PAYMENT_RUN = `${API_URL}payments/payment-runs/create`;

export const GET_PAYMENT_RUN = (PAYMENT_RUN_ID) => `${API_URL}payments/payment-runs/payment-run/${PAYMENT_RUN_ID}`;

export const EXPORT_PAYMENT_RUN = (PAYMENT_RUN_ID) => `${API_URL}payments/payment-runs/payment-run/export/${PAYMENT_RUN_ID}`;

export const EXPORT_NEW_FOR_PAYMENT = `${API_URL}payments/payment-runs/payment-run/new/export/`;

export const XERO_DISCONNECT = `${API_URL}xero/disconnect`;

export const GET_BOOKING_PLACEHOLDERS = `${API_URL}placeholder/find`;

export const GET_PLACEHOLDER_BOOKING = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}`;

export const GET_EXTERNAL_PLACEHOLDER_BOOKING = (BOOKING_ID, TOKEN) => `${API_URL}placeholder/external/${BOOKING_ID}/${TOKEN}`;

export const GET_EXTERNAL_CLIENT_PLACEHOLDER_BOOKING = (BOOKING_ID, TOKEN) => `${API_URL}placeholder/external/client/${BOOKING_ID}/${TOKEN}`;

export const CREATE_PLACEHOLDER_BOOKING = `${API_URL}placeholder`;

export const UPDATE_PLACEHOLDER_BOOKING = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}`;

export const UPDATE_PLACEHOLDER_JOB_FEED = (BOOKING_ID) => `${API_URL}placeholder/jobboard/${BOOKING_ID}`;

export const UPDATE_PLACEHOLDER_BOOKING_CANDIDATES = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/candidates`;

export const CANCEL_PLACEHOLDER_BOOKING = (BOOKING_ID) => `${API_URL}placeholder/cancel/${BOOKING_ID}`;

export const ARCHIVE_PLACEHOLDER_BOOKING = (BOOKING_ID) => `${API_URL}placeholder/archive/${BOOKING_ID}`;

export const ASSIGN_PLACEHOLDER_BOOKING = (BOOKING_ID) => `${API_URL}placeholder/assignee/${BOOKING_ID}`;

export const SEARCH_BOOKING_PLACEHOLDER_CANDIDATES = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/candidates/find`;

export const SEARCH_BOOKING_PLACEHOLDER_ASSIGNED_CANDIDATES = (BOOKING_ID, CLIENT_ID) => `${API_URL}placeholder/${BOOKING_ID}/${CLIENT_ID}/candidates/find`;

export const SEARCH_BOOKING_PLACEHOLDER_ASSIGNED_CANDIDATES_INTERESTED = (BOOKING_ID, CLIENT_ID) => `${API_URL}placeholder/${BOOKING_ID}/${CLIENT_ID}/candidates/find/interested`;

export const BOOKING_PLACEHOLDER_LINK_DOCUMENT = (BOOKING_ID, CANDIDATE_ID, DOCUMENT_ID) => `${API_URL}placeholder/${BOOKING_ID}/document/link/${CANDIDATE_ID}/${DOCUMENT_ID}`;

export const BOOKING_PLACEHOLDER_UNLINK_DOCUMENT = (BOOKING_ID, CANDIDATE_ID) => `${API_URL}placeholder/${BOOKING_ID}/document/unlink/${CANDIDATE_ID}`;

export const BOOKING_PLACEHOLDER_UNLINK_DEFAULT_DOCUMENT = (BOOKING_ID, CANDIDATE_ID) => `${API_URL}placeholder/${BOOKING_ID}/document/unlink/default/${CANDIDATE_ID}`;

export const BOOKING_PLACEHOLDER_CLIENT_INTEREST = (BOOKING_ID, TOKEN) => `${API_URL}placeholder/${BOOKING_ID}/${TOKEN}/clientCandidateInterest`;

export const BOOKING_PLACEHOLDER_CLIENT_INTEREST_INTERNAL = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/clientCandidateInterest/internal`;

export const BOOKING_PLACEHOLDER_CLIENT_INTEREST_SUBMIT = (BOOKING_ID, TOKEN) => `${API_URL}placeholder/${BOOKING_ID}/${TOKEN}/clientCandidateInterest/submit`;

export const SEARCH_BOOKING_PLACEHOLDER_ASSIGNED_CANDIDATES_STUBS = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/candidates/find/stubs`;

export const BOOKING_PLACEHOLDER_ENQUIRE = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/enquire`;

export const BOOKING_PLACEHOLDER_CLIENT_ENQUIRE = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/clientEnquire`;

export const UPDATE_EXTERNAL_PLACEHOLDER_BOOKING = (BOOKING_ID, TOKEN) => `${API_URL}placeholder/external/${BOOKING_ID}/${TOKEN}`;

export const GET_BOOKING_PLACEHOLDER_ENQUIRY_STATUS = (BOOKING_ID) => `${API_URL}placeholder/${BOOKING_ID}/existingenquiry`;

export const COMPLETE_BOOKING = `${API_URL}booking/complete`;

export const BOOKING_PLACEHOLDER_CANDIDATE_INTEREST = (BOOKING_ID, CANDIDATE_ID, STATUS) => `${API_URL}placeholder/${BOOKING_ID}/candidates/${CANDIDATE_ID}/update/${STATUS}`;

export const CANDIDATE_REQUEST_AVAILABILITY = (CANDIDATE_ID) => `${API_URL}candidate/availability/${CANDIDATE_ID}`;

export const CANDIDATES_REQUEST_AVAILABILITY = `${API_URL}candidate/bulk/availability`;

export const CANDIDATES_REQUEST_AVAILABILITY_RESEND = `${API_URL}candidate/bulk/availability/resend`;

export const GET_CANDIDATE_AVAILABILITY_ENQUIRY_STATUS = (CANDIDATE_ID) => `${API_URL}candidate/${CANDIDATE_ID}/existingavailabilityenquiry`;

export const GET_CANDIDATE_AVAILABILITY = (CANDIDATE_ID) => `${API_URL}candidate/availability/${CANDIDATE_ID}`;

export const GET_CANDIDATE_AVAILABILITY_BOOKINGS = (CANDIDATE_ID) => `${API_URL}candidate/availability/bookings/${CANDIDATE_ID}`;

export const GET_CANDIDATE_AVAILABILITY_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/availability/${CANDIDATE_ID}/${TOKEN}`;

export const UPDATE_CANDIDATE_AVAILABILITY_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}candidate/availability/${CANDIDATE_ID}/${TOKEN}`;

export const UPDATE_CANDIDATE_AVAILABILITY = (CANDIDATE_ID) => `${API_URL}candidate/availability/${CANDIDATE_ID}`;

export const SEND_CANDIDATE_DOCUMENTS = (CANDIDATE_ID) => `${API_URL}candidate/send/${CANDIDATE_ID}`;

export const SEND_CANDIDATE_EMAIL = (CANDIDATE_ID) => `${API_URL}candidate/email/send/${CANDIDATE_ID}`;

export const SEND_CLIENT_DOCUMENTS = (CONTACT_ID) => `${API_URL}client/documents/send/${CONTACT_ID}`;

export const SEND_CANDIDATE_KID = (CANDIDATE_ID) => `${API_URL}candidate/kid/${CANDIDATE_ID}`;

export const SEND_CANDIDATE_SMS = (CANDIDATE_ID) => `${API_URL}candidate/sms/send/${CANDIDATE_ID}`;

export const GET_FORM_QUESTIONS = (TYPE) => `${API_URL}questions/${TYPE}`;

export const GET_FORM_QUESTIONS_EXTERNAL = (TOKEN, CANDIDATE_ID, TYPE) => `${API_URL}questions/external/${TOKEN}/${CANDIDATE_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_EXTERNAL_REFERENCE = (TOKEN, CANDIDATE_ID, TYPE) => `${API_URL}questions/reference/${TOKEN}/${CANDIDATE_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_EXTERNAL_DOCUMENT = (TOKEN, CANDIDATE_ID, TYPE) => `${API_URL}questions/document/${TOKEN}/${CANDIDATE_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_EXTERNAL_CANDIDATE_TIMESHEET = (TOKEN, CANDIDATE_ID, TYPE) => `${API_URL}questions/timesheet/${TOKEN}/${CANDIDATE_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_EXTERNAL_CLIENT_TIMESHEET = (TOKEN, CLIENT_ID, TYPE) => `${API_URL}questions/timesheetclient/${TOKEN}/${CLIENT_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_ONBOARD = (TOKEN, CANDIDATE_ID, TYPE) => `${API_URL}questions/onboard/${TOKEN}/${CANDIDATE_ID}/${TYPE}`;

export const GET_FORM_QUESTIONS_JOB_BOARD = (TYPE) => `${API_URL}questions/jobboard/${TYPE}`;

export const UPDATE_DOCUMENT = (DOCUMENT_ID) => `${API_URL}document/update/${DOCUMENT_ID}`;

export const EMAIL_PREFERENCES = `${API_URL}emailPreferences`;

export const CREATE_STUDENT = `${API_URL}student`;

export const UPDATE_STUDENT = (STUDENT_ID) => `${API_URL}student/${STUDENT_ID}`;

export const GET_STUDENTS = `${API_URL}students/find`;

export const GET_STUDENT = (STUDENT_ID) => `${API_URL}student/${STUDENT_ID}`;

export const GET_BOOKINGS_BY_STUDENT = (STUDENT_ID) => `${API_URL}booking/student/${STUDENT_ID}`;

export const GET_TUITION_REPORTS = `${API_URL}tuition-reports/find`;

export const EXPORT_TUITION_REPORTS = `${API_URL}tuition-reports/export`;

export const BRANCHES = `${API_URL}customer/branches`;

export const BRANCHES_STUB = `${API_URL}customer/branches/stub`;

export const ADD_BRANCH = `${API_URL}customer/branch`;

export const UPDATE_BRANCH = (BRANCH_ID) => `${API_URL}customer/branch/${BRANCH_ID}`;

export const GET_BRANCH_USERS = (BRANCH_ID) => `${API_URL}customer/branch/${BRANCH_ID}/users`;

export const GET_USER_BRANCHES = (USER_ID) => `${API_URL}customer/branch/${USER_ID}/branches`;

export const GET_SUBSCRIPTION_BRANCHES_STUB = `${API_URL}customer/branch/filter/branches`;

export const ASSIGN_BRANCH_USERS = (BRANCH_ID) => `${API_URL}customer/branch/${BRANCH_ID}/users/assign`;

export const ASSIGN_USER_BRANCHES = (USER_ID) => `${API_URL}customer/branch/${USER_ID}/branches/assign`;

export const UNASSIGN_BRANCH_USERS = (BRANCH_ID) => `${API_URL}customer/branch/${BRANCH_ID}/users/unassign`;

export const UNASSIGN_USER_BRANCHES = (USER_ID) => `${API_URL}customer/branch/${USER_ID}/branches/unassign`;

export const UMBRELLA_COMPANIES = `${API_URL}customer/umbrella`;

export const UMBRELLA_COMPANIES_STUB = `${API_URL}customer/umbrella/stub`;

export const CREATE_UMBRELLA_COMPANY = `${API_URL}customer/umbrella/create`;

export const UPDATE_UMBRELLA_COMPANY = (UMBRELLA_ID) => `${API_URL}customer/umbrella/${UMBRELLA_ID}`;

export const GET_USER_TARGETS = (USER_ID) => `${API_URL}customer/target/${USER_ID}/targets`;

export const ADD_TARGET = `${API_URL}customer/target`;

export const UPDATE_TARGET = (TARGET_ID) => `${API_URL}customer/target/${TARGET_ID}`;

export const MOVE_CANDIDATES = `${API_URL}candidates/move`;

export const MOVE_CLIENTS = `${API_URL}clients/move`;

export const GET_CANDIDATE_COMPLIANCE_TOTALS = `${API_URL}candidates/compliance/totals`;

export const GET_CANDIDATES_COMPLIANCE = `${API_URL}candidates/compliance`;

export const GET_DOCUMENT_VERIFICATIONS = `${API_URL}document/compliance/verifications`;

export const EXPORT_CANDIDATES_COMPLIANCE = `${API_URL}candidates/compliance/export`;

export const GET_CANDIDATE_REFERENCES = (CANDIDATE_ID) => `${API_URL}candidate/references/${CANDIDATE_ID}`;

export const GET_NOTIFICATIONS = `${API_URL}notifications`;

export const MARK_GLOBAL_NOTIFICATIONS_READ = `${API_URL}notifications/global/read`;

export const MANAGE_DO_NOT_WORK = (CLIENT_ID) => `${API_URL}client/donotwork/${CLIENT_ID}`;

export const MANAGE_DO_NOT_WORK_CANDIDATE = (CANDIDATE_ID) => `${API_URL}candidate/donotwork/${CANDIDATE_ID}`;

export const DELETE_CANDIDATE_DO_NOT_WORK = (CLIENT_ID, CANDIDATE_ID) => `${API_URL}client/donotwork/${CLIENT_ID}/${CANDIDATE_ID}`;

export const GET_CANDIDATES_PROFILE_COMPLETIONS = `${API_URL}candidates/profile-completions`;

// BOOKING SETTINGS
export const BOOKING_SETTINGS = `${API_URL}bookingSettings`;

export const BOOKING_SETTINGS_WSOP = `${API_URL}bookingSettings/writtenStatement`;

export const BOOKING_SETTINGS_WSOP_CONTENT = `${API_URL}bookingSettings/wsop`;

export const BOOKING_SETTINGS_WSOP_PREVIEW = `${API_URL}bookingSettings/wsop/preview`;
//////////

// SUMMARIES
export const GET_CLIENT_CONTACT_SUMMARY = (CLIENT_ID) => `${API_URL}client/contact/summary/${CLIENT_ID}`;

export const GET_CANDIDATE_CONTACT_SUMMARY = (CANDIDATE_ID) => `${API_URL}candidate/contact/summary/${CANDIDATE_ID}`;
//////////

// LOG ERROR
export const LOG_ERROR = `${API_URL}monitor/error`;
//////////

// Candidate Config
export const CANDIDATE_CONFIG = `${API_URL}candidateConfig`;

export const CANDIDATE_ONBOARDING_CONFIG = `${API_URL}candidateConfig/onboarding`;

export const CANDIDATE_CONFIG_ONBOARD = (CANDIDATE_ID, TOKEN) => `${API_URL}candidateConfig/${CANDIDATE_ID}/${TOKEN}`;

export const CANDIDATE_ONBOARDING_COMPLETE_CONFIG = (CANDIDATE_ID) => `${API_URL}candidate/section-status/${CANDIDATE_ID}`;
//////////

// Candidate Profile Summary
export const GET_CANDIDATE_PROFILE_SUMMARY = (CANDIDATE_ID) => `${API_URL}candidateProfileSummary/${CANDIDATE_ID}`;
//////////

// EMAILS
export const PREVIEW_EMAIL = (TYPE, RECORD) => `${API_URL}emails/preview/${TYPE}/${RECORD}`;
//////////

// Candidate Interview
export const CANDIDATE_INTERVIEW_QUESTIONS = (CANDIDATE_ID) => `${API_URL}candidateInterview/${CANDIDATE_ID}`;

export const CANDIDATE_INTERVIEW_QUESTIONS_CONFIRMATION = `${API_URL}candidateInterview/approval/send`;

export const CANDIDATE_INTERVIEW_QUESTIONS_MANUAL_CONFIRMATION = `${API_URL}candidateInterview/approval/manual`;

export const CANDIDATE_INTERVIEW_QUESTIONS_EXTERNAL = (CANDIDATE_ID, TOKEN) => `${API_URL}candidateInterview/approval/${CANDIDATE_ID}/${TOKEN}`;

export const CANDIDATE_INTERVIEW_QUESTIONS_SUBMIT_EXTERNAL = (TOKEN) => `${API_URL}candidateInterview/submit/${TOKEN}`;
//////////

// AVAILABILITY MANAGER
export const GET_DRAFT_FILTERED_BOOKINGS = `${API_URL}bookings/drafts/filtered`;

export const GET_CANDIDATES_FOR_AVAILABILITY_MANAGER = `${API_URL}availability/candidates`;

export const VALIDATE_AVAILABILITY_PLACEMENENT = `${API_URL}availability/validate`;

export const ASSIGN_AVAILABILITY_PLACEMENENT = `${API_URL}availability/assign`;
//////////

// SC
export const GET_SHORT_CODE = `${API_URL}sc/redirect`;
//////////

// STRIPE
export const STRIPE_CREATE_INTENT = `${API_URL}stripe/create-intent`;
//////////

// CANDIDATE SETTINGS
export const CANDIDATE_SETTINGS = `${API_URL}candidateSettings`;

export const UPDATE_CANDIDATE_SETTINGS = `${API_URL}candidateSettings/kid`;

export const CANDIDATE_SETTINGS_KID_PREVIEW = `${API_URL}candidateSettings/kid/preview`;
//////////

// JOB BOARD
export const GENERATE_JOBBOARD_API_KEY = `${API_URL}jobboards/key`;

export const SAVE_CV_LIBRARY_CREDENTIALS = `${API_URL}cvLibrary/credentials`;

export const VALIDATE_CV_LIBRARY_CREDENTIALS = `${API_URL}cvLibrary/credentials/validate`;

export const GET_JOBBOARD = `${API_URL}jobboard`;

export const GET_CV_LIBRARY_JOB = (BOOKING_ID) => `${API_URL}cvLibraryJob/${BOOKING_ID}`;

export const SEARCH_JOBBOARD = `${API_URL}jobboard/search`;

export const JOBBOARD_APPLY = (BOOKING_ID) => `${API_URL}jobboard/apply/${BOOKING_ID}`;

export const JOBBOARD_APPLY_FROM_CV_LIBRARY = (BOOKING_ID) => `${API_URL}jobboard/applyFromCvLibrary/${BOOKING_ID}`;
//////////

// TAGS
export const GET_REFERENCE_TAGS = (REFERENCE_ID) => `${API_URL}tags/${REFERENCE_ID}`;

export const GET_TAG_SUGGESTIONS = `${API_URL}tags/search`;

export const GET_LATEST_TAGS = `${API_URL}tags/recent`;

export const CREATE_TAG = `${API_URL}tags`;

export const ASSIGN_TAG = `${API_URL}tags/assign`;

export const UNASSIGN_TAG = `${API_URL}tags/assign`;

export const UPDATE_TAG = (TAG_ID) => `${API_URL}tag/${TAG_ID}`;
//////////

//SEND CLIENT TIMESHEET
export const SEND_CLIENT_TIMESHEET = (TIMESHEET_ID) => `${API_URL}timesheets/send-timesheet-to-client/${TIMESHEET_ID}`;
//////////

//DOWNLOAD BOOKING CONFIRMATION
export const DOWNLOAD_BOOKING_CONFIRMATION = (CONFIRMATION_TYPE) => `${API_URL}booking/export/booking-confirmation/${CONFIRMATION_TYPE}`;
//////////

//FRESHDESK
export const FRESH_DESK_CONNECT = `${API_URL}freshdesk/sso`;

export const FRESH_DESK_GENERATE_URL = `${API_URL}freshdesk/generateurl`;
//////////

//CV LIBRARY
export const POST_JOB_LISTING_TO_CV_LIBRARY = `${API_URL}cvLibrary/post`;

export const DELETE_JOB_LISTING_FROM_CV_LIBRARY = `${API_URL}cvLibrary/delete`;
//////////

//AUTOMATION
export const CREATE_AUTOMATION = `${AUTOMATION_API_URL}automations`;

export const DELETE_AUTOMATION = (AUTOMATION_ID) => `${AUTOMATION_API_URL}automations/delete/${AUTOMATION_ID}`;

export const DISABLE_AUTOMATION = (AUTOMATION_ID) => `${AUTOMATION_API_URL}automations/disable/${AUTOMATION_ID}`;

export const GET_ALL_AUTOMATIONS = `${AUTOMATION_API_URL}automations`;

export const GET_SINGLE_AUTOMATION = (AUTOMATION_ID) => `${AUTOMATION_API_URL}automations/singleAutomation/${AUTOMATION_ID}`;

export const UPDATE_AUTOMATION = (AUTOMATION_ID) => `${AUTOMATION_API_URL}automations/${AUTOMATION_ID}`;

export const GET_CRITERIA_OPTIONS = `${AUTOMATION_API_URL}automations/criteriaOptions`;

export const GET_FOCUS_OPTIONS = `${AUTOMATION_API_URL}automations/focusOptions`;

export const GET_ACTION_OPTIONS = `${AUTOMATION_API_URL}automations/actionOptions`;

export const CREATE_AUTOMATION_TAG = `${AUTOMATION_API_URL}automations/createTag`;

export const GET_AUTOMATION_TARGET_DETAILS = (AUTOMATION_ID) => `${AUTOMATION_API_URL}automations/targetDetails/${AUTOMATION_ID}`;
//////////

// CUSTOMER DOCUMENTS
export const GET_ALL_CUSTOMER_DOCUMENTS = `${API_URL}alldocuments/documents/find`;

export const GET_CUSTOMER_DOCUMENTS = `${API_URL}customerdocuments/documents/find`;

export const GET_CUSTOMER_ONBOARDING_DOCUMENTS = (TOKEN) => `${API_URL}customerdocuments/documents/find/${TOKEN}`;

export const GET_CUSTOMER_ONBOARDING_DOCUMENTS_DOCUMENTS = (TOKEN) => `${API_URL}customerdocuments/documents/find/external/${TOKEN}`;

export const UPDATE_CUSTOMER_DOCUMENTS = `${API_URL}customerdocuments/documents`;
//////////

// CUSTOMER DOCUMENTS

export const GET_CUSTOMER_OCCUPATIONS = `${API_URL}customeroccupations`;

export const GET_CUSTOMER_OCCUPATIONS_ONBOARDING = (TOKEN) => `${API_URL}customeroccupations/onboarding/${TOKEN}`;

export const UPDATE_CUSTOMER_OCCUPATIONS = `${API_URL}customeroccupations`;

//////////

//WATCHERS
export const GET_WATCHER = (RECORD_ID) => `${API_URL}watchers/${RECORD_ID}`;

export const GET_WATCHERS = (RECORD_ID) => `${API_URL}watchers/${RECORD_ID}/watchers`;

export const UPDATE_WATCHER = (RECORD_ID) => `${API_URL}watchers/${RECORD_ID}`;

export const UPDATE_WATCHERS = (RECORD_ID) => `${API_URL}watchers/${RECORD_ID}/watchers`;
//////////

// TABLE CONFIGURATION
export const GET_TABLE_CONFIG = (TABLE_ID) => `${API_URL}tableConfig/${TABLE_ID}`;

export const UPDATE_TABLE_CONFIG = (TABLE_ID) => `${API_URL}tableConfig/${TABLE_ID}`;
//////////

//COMPANY TARGETS
export const GET_COMPANY_TARGETS = (COMPANY_ID) => `${API_URL}customer/company/target/${COMPANY_ID}/targets`;

export const ADD_COMPANY_TARGET = `${API_URL}customer/company/target`;

export const UPDATE_COMPANY_TARGET = (TARGET_ID) => `${API_URL}customer/company/target/${TARGET_ID}`;

export const GET_USERS_BY_COMPANY_OR_BRANCH = `${API_URL}usersByCompanyOrBranch`;
///////////
