export interface ICandidateInterviewQuestions {
	type: CandidateInterviewQuestonTypes;
	interviewDate: string;
	firstName: string;
	lastName: string;
	email: string;
	contactNumber: string;
	postcode: string;
	startDate: string;
	typeOfWork: string;
	employmentType: CandidateInterviewWorkTypes;
	transport: string;
	durationOfTravel: string;
	ageGroupPreference: string;
	subjects: string;
	whyTeacher: string;
	qualities: string;
	experienceAdditionalNeeds: boolean;
	experienceAdditionalNeedsDescription: string;
	successDescription: string;
	teachingStyle: string;
	adaptingWork: string;
	specalistSubjects: string;
	challengingBehaviourScenario: string;
	challengingBehaviourSolution: string;
	confirmGaps: string;
	mostRecentEmployment: string;
	rolesOrClubs: string;
	otherAgencies: string;
	teaching360: boolean;
	currentDbs: boolean;
	updateService: boolean;
	updateServiceApply: boolean;
	askedToLeave: boolean;
	dismissed: boolean;
	disciplinaryAction: boolean;
	criminalConvictions: boolean;
	safeguardingAllegations: string;
	childAtRisk: string;
	safeguardingDescription: string;
	confidence: string;
	interviewerName: string;
	interviewersEmail: string;
	strengthsSupport: string;
	notCooperatingScenario: string;
	notCooperatingScenarioSolution: string;
	behaviourManagement: string;
	schoolPreference: string;
	siblingsConflict: string;
	safeguardinRresponsibilities: boolean;
	safeguardinRresponsibilitiesSolution: string;
	videoInterview: string;
	//send
	schoolsWorked: string;
	registeredAgencies: string;
	educationAdditionalNeedsJustification: string;
	previousAdditionalNeedsExperience: string;
	educationAdditionalNeedsDescription: string;
	additionalNeedsAchievement: string;
	transferableSkills: string;
	additionalNeedsQuality: string;
	differenceMade: string;
	sendTaRole: string;
	resilience: string;
	mostRecentExperience?: string;
	typesOfSend?: string;
	happyPersonalCare?: string;
	extraTraining?: string;
	recentSafeGuardingTraining?: string;
	childSecret?: string;
	difficultBehaviour?: string;
	exampleChallengingBehaviourSend?: string;
	therapeuticApproachExample?: string;
	//ta
	previousTa: boolean;
	whyTa: string;
	keyRoleTa: string;
	skillsAsTa: string;
	skillsImportantAsTa: string;
	differenceAsTa: string;
	adjustmentsAdditionalNeeds: string;
	//primary
	whyPrimary: string;
	successTeacher: string;
	differenceAsTeacher: string;
	//approval
	candidateApprovalSent?: string;
	candidateApprovalSignature?: string;
	candidateApprovalSignatureDate?: string;
	candidateApprovalNote?: string;
	//exam invigilator
	whyExamInvigilator?: string;
	experienceExamInvigilator?: string;
	keyRoleExamInvigilator?: string;
	//nurture teacher
	bespokeCurriculum?: string;
	tailoredLearning?: string;
	specialNeedsExperience?: string;
	teachingApproach?: string;
	successfulOutcomes?: string;
	stayingInformed?: string;
	recentTraining?: string;
	applyingKnowledge?: string;
	therapeuticApproach?: string;
	supportingSensoryNeeds?: string;
	sensoryNeedsStrategies?: string;
	preferredAgeSend?: string;
	favouriteSubjectSend?: string;
	engagingMaterial?: string;
	challengingBehaviourSend?: string;
	proactiveMeasures?: string;
	safeguardSendStudents?: string;
	signsOfDistress?: string;
	handledSendSafeguardingIssue?: string;
	//nursery
	whyNursery?: string;
	helpedChild?: string;
	supportChildDevelopment?: string;
	ensureSafety?: string;
	communicateWithParents?: string;
	ageGroupExperience?: string;
	validDBSCheck?: string;
	//tutor
	tutorAttraction?: string;
	tutorQualities?: string;
	tutorTransferableSkills?: string;
	tutorImportantQuality?: string;
	tutorIntervention?: string;
	tutorOnetoOne?: string;
	tutorVulnerable?: string;
	tutorAdditionalNeeds?: string;
	tutorAchievement?: string;
	tutorCYP?: string;
}

export enum CandidateInterviewQuestonTypes {
	Secondary = 1,
	SENDTA = 2,
	Primary = 3,
	GeneralTA = 4,
	CoverSupervisor = 5,
	ExamInvigilator = 6,
	NurseryTeacher = 7,
	NurtureTeacher = 8,
	Tutor = 9,
}

export enum CandidateInterviewWorkTypes {
	Full = 1,
	Part = 2,
	Both = 3,
}

export class CandidateInterviewModel implements ICandidateInterviewQuestions {
	type: CandidateInterviewQuestonTypes;
	interviewDate: string;
	firstName: string;
	lastName: string;
	email: string;
	contactNumber: string;
	postcode: string;
	startDate: string;
	typeOfWork: string;
	employmentType: CandidateInterviewWorkTypes;
	transport: string;
	durationOfTravel: string;
	ageGroupPreference: string;
	subjects: string;
	whyTeacher: string;
	qualities: string;
	experienceAdditionalNeeds: boolean;
	experienceAdditionalNeedsDescription: string;
	successDescription: string;
	teachingStyle: string;
	adaptingWork: string;
	specalistSubjects: string;
	challengingBehaviourScenario: string;
	challengingBehaviourSolution: string;
	confirmGaps: string;
	mostRecentEmployment: string;
	rolesOrClubs: string;
	otherAgencies: string;
	teaching360: boolean;
	currentDbs: boolean;
	updateService: boolean;
	updateServiceApply: boolean;
	askedToLeave: boolean;
	dismissed: boolean;
	disciplinaryAction: boolean;
	criminalConvictions: boolean;
	safeguardingAllegations: string;
	childAtRisk: string;
	safeguardingDescription: string;
	confidence: string;
	interviewerName: string;
	interviewersEmail: string;
	strengthsSupport: string;
	notCooperatingScenario: string;
	notCooperatingScenarioSolution: string;
	behaviourManagement: string;
	schoolPreference: string;
	siblingsConflict: string;
	safeguardinRresponsibilities: boolean;
	safeguardinRresponsibilitiesSolution: string;
	videoInterview: string;
	//send
	schoolsWorked: string;
	registeredAgencies: string;
	educationAdditionalNeedsJustification: string;
	previousAdditionalNeedsExperience: string;
	educationAdditionalNeedsDescription: string;
	additionalNeedsAchievement: string;
	transferableSkills: string;
	additionalNeedsQuality: string;
	differenceMade: string;
	sendTaRole: string;
	resilience: string;
	mostRecentExperience?: string;
	typesOfSend?: string;
	happyPersonalCare?: string;
	extraTraining?: string;
	recentSafeGuardingTraining?: string;
	childSecret?: string;
	difficultBehaviour?: string;
	exampleChallengingBehaviourSend?: string;
	therapeuticApproachExample?: string;
	//ta
	previousTa: boolean;
	whyTa: string;
	keyRoleTa: string;
	skillsAsTa: string;
	skillsImportantAsTa: string;
	differenceAsTa: string;
	adjustmentsAdditionalNeeds: string;
	//primary
	whyPrimary: string;
	successTeacher: string;
	differenceAsTeacher: string;
	//candidate approval
	candidateApprovalSent?: string;
	candidateApprovalSignature?: string;
	candidateApprovalSignatureDate?: string;
	candidateApprovalNote?: string;
	//exam invigilator
	whyExamInvigilator?: string;
	experienceExamInvigilator?: string;
	keyRoleExamInvigilator?: string;
	//nurture teacher
	bespokeCurriculum?: string;
	tailoredLearning?: string;
	specialNeedsExperience?: string;
	teachingApproach?: string;
	successfulOutcomes?: string;
	stayingInformed?: string;
	recentTraining?: string;
	applyingKnowledge?: string;
	therapeuticApproach?: string;
	supportingSensoryNeeds?: string;
	sensoryNeedsStrategies?: string;
	preferredAgeSend?: string;
	favouriteSubjectSend?: string;
	engagingMaterial?: string;
	challengingBehaviourSend?: string;
	proactiveMeasures?: string;
	safeguardSendStudents?: string;
	signsOfDistress?: string;
	handledSendSafeguardingIssue?: string;
	///nursery
	whyNursery?: string;
	helpedChild?: string;
	supportChildDevelopment?: string;
	ensureSafety?: string;
	communicateWithParents?: string;
	ageGroupExperience?: string;
	validDBSCheck?: string;
	//tutor
	tutorAttraction?: string;
	tutorQualities?: string;
	tutorTransferableSkills?: string;
	tutorImportantQuality?: string;
	tutorIntervention?: string;
	tutorOnetoOne?: string;
	tutorVulnerable?: string;
	tutorAdditionalNeeds?: string;
	tutorAchievement?: string;
	tutorCYP?: string;

	constructor(data?: ICandidateInterviewQuestions) {
		this.type = data?.type || null;
		this.interviewDate = data?.interviewDate || "";
		this.firstName = data?.firstName || "";
		this.lastName = data?.lastName || "";
		this.email = data?.email || "";
		this.contactNumber = data?.contactNumber || "";
		this.postcode = data?.postcode || "";
		this.startDate = data?.startDate || "";
		this.typeOfWork = data?.typeOfWork || null;
		this.employmentType = data?.employmentType || null;
		this.transport = data?.transport || "";
		this.durationOfTravel = data?.durationOfTravel || "";
		this.ageGroupPreference = data?.ageGroupPreference || "";
		this.subjects = data?.subjects || "";
		this.whyTeacher = data?.whyTeacher || "";
		this.qualities = data?.qualities || "";
		this.experienceAdditionalNeeds = data?.experienceAdditionalNeeds !== null && data?.experienceAdditionalNeeds !== undefined ? data?.experienceAdditionalNeeds : null;
		this.experienceAdditionalNeedsDescription = data?.experienceAdditionalNeedsDescription || "";
		this.successDescription = data?.successDescription || "";
		this.teachingStyle = data?.teachingStyle || "";
		this.adaptingWork = data?.adaptingWork || "";
		this.specalistSubjects = data?.specalistSubjects || "";
		this.challengingBehaviourScenario = data?.challengingBehaviourScenario || "";
		this.challengingBehaviourSolution = data?.challengingBehaviourSolution || "";
		this.confirmGaps = data?.confirmGaps || "";
		this.mostRecentEmployment = data?.mostRecentEmployment || "";
		this.rolesOrClubs = data?.rolesOrClubs || "";
		this.otherAgencies = data?.otherAgencies || "";
		this.teaching360 = data?.teaching360 !== null && data?.teaching360 !== undefined ? data?.teaching360 : null;
		this.currentDbs = data?.currentDbs !== null && data?.currentDbs !== undefined ? data?.currentDbs : null;
		this.updateService = data?.updateService !== null && data?.updateService !== undefined ? data?.updateService : null;
		this.updateServiceApply = data?.updateServiceApply !== null && data?.updateServiceApply !== undefined ? data?.updateServiceApply : null;
		this.askedToLeave = data?.askedToLeave !== null && data?.askedToLeave !== undefined ? data?.askedToLeave : null;
		this.dismissed = data?.dismissed !== null && data?.dismissed !== undefined ? data?.dismissed : null;
		this.disciplinaryAction = data?.disciplinaryAction !== null && data?.disciplinaryAction !== undefined ? data?.disciplinaryAction : null;
		this.safeguardingAllegations = data?.safeguardingAllegations || "";
		this.childAtRisk = data?.childAtRisk || "";
		this.criminalConvictions = data?.criminalConvictions !== null && data?.criminalConvictions !== undefined ? data?.criminalConvictions : null;
		this.safeguardingDescription = data?.safeguardingDescription || "";
		this.confidence = data?.confidence || "";
		this.interviewerName = data?.interviewerName || "";
		this.interviewersEmail = data?.interviewersEmail || "";
		this.schoolsWorked = data?.schoolsWorked || "";
		this.registeredAgencies = data?.registeredAgencies || "";
		this.educationAdditionalNeedsJustification = data?.educationAdditionalNeedsJustification || "";
		this.previousAdditionalNeedsExperience = data?.previousAdditionalNeedsExperience || "";
		this.educationAdditionalNeedsDescription = data?.educationAdditionalNeedsDescription || "";
		this.additionalNeedsAchievement = data?.additionalNeedsAchievement || "";
		this.transferableSkills = data?.transferableSkills || "";
		this.additionalNeedsQuality = data?.additionalNeedsQuality || "";
		this.differenceMade = data?.differenceMade || "";
		this.sendTaRole = data?.sendTaRole || "";
		this.resilience = data?.resilience || "";
		this.mostRecentExperience = data?.mostRecentExperience || "";
		this.typesOfSend = data?.typesOfSend || "";
		this.happyPersonalCare = data?.happyPersonalCare || "";
		this.extraTraining = data?.extraTraining || "";
		this.recentSafeGuardingTraining = data?.recentSafeGuardingTraining || "";
		this.childSecret = data?.childSecret || "";
		this.difficultBehaviour = data?.difficultBehaviour || "";
		this.exampleChallengingBehaviourSend = data?.exampleChallengingBehaviourSend || "";
		this.therapeuticApproachExample = data?.therapeuticApproachExample || "";
		this.previousTa = data?.previousTa !== null && data?.previousTa !== undefined ? data?.previousTa : null;
		this.whyTa = data?.whyTa || "";
		this.keyRoleTa = data?.keyRoleTa || "";
		this.skillsAsTa = data?.skillsAsTa || "";
		this.skillsImportantAsTa = data?.skillsImportantAsTa || "";
		this.differenceAsTa = data?.differenceAsTa || "";
		this.adjustmentsAdditionalNeeds = data?.adjustmentsAdditionalNeeds || "";
		this.whyPrimary = data?.whyPrimary || "";
		this.successTeacher = data?.successTeacher || "";
		this.differenceAsTeacher = data?.differenceAsTeacher || "";
		this.strengthsSupport = data?.strengthsSupport || "";
		this.notCooperatingScenario = data?.notCooperatingScenario || "";
		this.notCooperatingScenarioSolution = data?.notCooperatingScenarioSolution || "";
		this.behaviourManagement = data?.behaviourManagement || "";
		this.schoolPreference = data?.schoolPreference || "";
		this.siblingsConflict = data?.siblingsConflict || "";
		this.safeguardinRresponsibilities = data?.safeguardinRresponsibilities !== null && data?.safeguardinRresponsibilities !== undefined ? data?.safeguardinRresponsibilities : null;
		this.safeguardinRresponsibilitiesSolution = data?.safeguardinRresponsibilitiesSolution || "";
		this.videoInterview = data?.videoInterview || "";
		this.candidateApprovalSent = data?.candidateApprovalSent || null;
		this.candidateApprovalSignature = data?.candidateApprovalSignature || null;
		this.candidateApprovalSignatureDate = data?.candidateApprovalSignatureDate || null;
		this.candidateApprovalNote = data?.candidateApprovalNote || null;
		this.whyExamInvigilator = data?.whyExamInvigilator || "";
		this.experienceExamInvigilator = data?.experienceExamInvigilator || "";
		this.keyRoleExamInvigilator = data?.keyRoleExamInvigilator || "";
		this.bespokeCurriculum = data?.bespokeCurriculum || "";
		this.tailoredLearning = data?.tailoredLearning || "";
		this.specialNeedsExperience = data?.specialNeedsExperience || "";
		this.teachingApproach = data?.teachingApproach || "";
		this.successfulOutcomes = data?.successfulOutcomes || "";
		this.stayingInformed = data?.stayingInformed || "";
		this.recentTraining = data?.recentTraining || "";
		this.applyingKnowledge = data?.applyingKnowledge || "";
		this.therapeuticApproach = data?.therapeuticApproach || "";
		this.supportingSensoryNeeds = data?.supportingSensoryNeeds || "";
		this.sensoryNeedsStrategies = data?.sensoryNeedsStrategies || "";
		this.preferredAgeSend = data?.preferredAgeSend || "";
		this.favouriteSubjectSend = data?.favouriteSubjectSend || "";
		this.engagingMaterial = data?.engagingMaterial || "";
		this.challengingBehaviourSend = data?.challengingBehaviourSend || "";
		this.proactiveMeasures = data?.proactiveMeasures || "";
		this.safeguardSendStudents = data?.safeguardSendStudents || "";
		this.signsOfDistress = data?.signsOfDistress || "";
		this.handledSendSafeguardingIssue = data?.handledSendSafeguardingIssue || "";
		this.whyNursery = data?.whyNursery || "";
		this.helpedChild = data?.helpedChild || "";
		this.supportChildDevelopment = data?.supportChildDevelopment || "";
		this.ensureSafety = data?.ensureSafety || "";
		this.communicateWithParents = data?.communicateWithParents || "";
		this.ageGroupExperience = data?.ageGroupExperience || "";
		this.validDBSCheck = data?.validDBSCheck || "";
		this.tutorAttraction = data?.tutorAttraction || "";
		this.tutorQualities = data?.tutorQualities || "";
		this.tutorTransferableSkills = data?.tutorTransferableSkills || "";
		this.tutorImportantQuality = data?.tutorImportantQuality || "";
		this.tutorIntervention = data?.tutorIntervention || "";
		this.tutorOnetoOne = data?.tutorOnetoOne || "";
		this.tutorVulnerable = data?.tutorVulnerable || "";
		this.tutorAdditionalNeeds = data?.tutorAdditionalNeeds || "";
		this.tutorAchievement = data?.tutorAchievement || "";
		this.tutorCYP = data?.tutorCYP || "";
	}
}
