export enum Permissions {
	CanManageUsers = 100,
	CanAddUsers = 101,
	CanEditUsers = 102,
	CanDeleteUsers = 103,
	CanManageClients = 200,
	CanAddClients = 201,
	CanEditClients = 202,
	CanDeleteClients = 203,
	CanSendTimesheets = 204,
	CanExportPersonnelClients = 205,
	CanExportClients = 206,
	CanManageCandidates = 300,
	CanAddCandidates = 301,
	CanEditCandidates = 302,
	CanDeleteCandidates = 303,
	CanExportPersonnelCandidates = 304,
	CanAnonymiseCandidates = 305,
	CanExportCandidates = 306,
	CandidateComplianceManager = 307,
	CandidateComplianceManagerEdit = 308,
	CandidateComplianceDashboard = 309,
	CandidateComplianceLimitPendingOwn = 310,
	CannotMoveCandidatesFromArchived = 311,
	CandidateComplianceManagerAssigned = 312,
	CandidateComplianceManagerPreventApproved = 313,
	CanApproveCandidateDocuments = 314,
	CanViewCandidateBooked = 315,
	CanEditLockedKCSIE = 316,
	CanManageBookings = 400,
	CanAddBookings = 401,
	CanEditBookings = 402,
	CanDeleteBookings = 403,
	CanExportBookings = 404,
	CanManageBookingSplits = 405,
	CanManageAllBookings = 406,
	CanOnlyPlaceApprovedCandidates = 410,
	CanManageTimesheets = 500,
	CanAddTimesheets = 501,
	CanEditTimesheets = 502,
	CanDeleteTimesheets = 503,
	CanEditInvoiceRaisedTimesheet = 504,
	CanManageMarketing = 600,
	CanAddMarketingTemplate = 601,
	CanEditMarketingTemplate = 602,
	CanDeleteMarketingTemplate = 603,
	CanAddMarketingSingleSend = 604,
	CanSenedCampaignFromSelectedUser = 605,
	CanManageMarketingSuppressions = 606,
	CanManageCustomer = 700,
	CanEditCustomer = 701,
	CanManageCustomerUsers = 702,
	CanAddCustomerUsers = 703,
	CanEditCustomerUsers = 704,
	CanManageCustomerSubscription = 705,
	CanToggleStats = 1000,
	CanViewStats = 1001,
	CanViewStatsDaysOut = 1002,
	CanViewStatsBookings = 1003,
	CanViewStatsAverageMargin = 1004,
	CanViewStatsProfit = 1005,
	CanViewStatsCommision = 1006,
	CanToggleUsers = 1007,
	CanViewStatsCommisionCompany = 1008,
	DefaultStatsToIndividual = 1009,
	CanViewAllClients = 1010,
	CanViewBranchStats = 1050,
	CanViewBranchUsers = 1051,
	CanManageInvoices = 1100,
	CanCreateInvoice = 1101,
	CanEditInvoice = 1102,
	CanManageJobBoards = 1103,
	CanManagePayments = 1200,
	CanCreatePayments = 1201,
	CanEditPayments = 1202,
	CanDeletePayments = 1203,
	CanManageSearchSelect = 1300,
	CanAddSearchSelect = 1301,
	CanEditSearchSelect = 1302,
	CanDeleteSearchSelect = 1303,
	CanManageAllSearchSelect = 1304,
	CanViewAuditTrail = 2000,
	CanViewAnalytics = 3000,
	CanViewAnalyticsClients = 3001,
	CanViewAnalyticsCandidates = 3002,
	CanViewAnalyticsBookings = 3003,
	CanViewAnalyticsTimesheets = 3004,
	CanViewAnalyticsMarketing = 3005,
	CanViewAnalyticsMarketingTemplate = 3006,
	CanViewAnalyticsPlaceholders = 3007,
	CanViewAnalyticsCompany = 3008,
	CanExportAnalytics = 3009,
	CanViewAnalyticsFinancial = 3010,
	CanViewAnalyticsFinancialLoss = 3011,
	CanViewUserTargetReporting = 3012,
	CanOnlyViewOwnUserTargetReporting = 3013,
	CanOViewTeamUserTargetReporting = 3014,
	CanAddBookingsAllClients = 4000,
	CanManageTutorBookings = 5000,
	CanManageCandidateVerification = 6000,
	CanManageAvailabilityManager = 7000,
	CanAddAvailabilityManager = 7001,
	CanEditAvailabilityManager = 7002,
	CanDeleteAvailabilityManager = 7003,
	CanManageAllAvailabilityManager = 7004,
	DataProviderSync = 8000,
	CanManageAutomations = 9000,
	CanDeleteTags = 10000,
	CanViewProfileCompletions = 11000,
	CanExportBookingFinancials = 20000,
}
