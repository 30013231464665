//If this enum is updated, please also update the equivalent enums used as this is used in analytics, app and api repos:
//Analytics: config/enums/companyTarget.enum.ts
//App: _enums/companyTargets.enum.ts
//Api: config/enums/companyTargets.enum.ts
//The following files also use this enum and will need to be updated:
//App: config/formData.js
//App: functions/helpers.tsx
//Analytics: controllers/helpers/report/targets/aggregations/other/typesByCategory.ts

export enum CompanyTargetTypes {
	////////////////////////

	//Bookings

	//Booking By Type
	BookingsStandardBookingsCreatedLine = 100,
	BookingsIATBookingsCreatedLine = 101,
	BookingsTotalBookingsStat = 102,

	//Booking By Day Changes
	BookingsBookingDaysAddedStat = 103,
	BookingsBookingDaysRemovedStat = 104,
	BookingsBookingDaysAddedAndRemovedTotalStat = 105,
	BookingsBookingDaysAddedMinusRemovedDaysStat = 106,

	//Booking Audit Reports
	BookingsBookingChangesLine = 107,
	BookingsBookingCostsChangedLine = 108,
	BookingsBookingsCancelledLine = 109,

	//Booking Profit Reports
	BookingsProfitLossBar = 110,
	BookingsProfitLossBySickness = 111,
	BookingsProfitLossByNoShow = 112,
	BookingsProfitLossByClientCancelled = 113,
	BookingsProfitLossByActOfGod = 114,
	BookingsProfitLossByPersonalAppointment = 115,
	BookingsProfitLossDaysRemovedStat = 116,
	BookingsProfitStat = 117,

	//Bookings Other
	BookingsAwrReminders = 118,

	////////////////////////

	//Candidates
	//Candidate Status Changes
	CandidatesCandidatesToStatus = 200,

	//Candidate Entries
	CandidatesCandidateEntry = 201,

	//Onboarding
	CandidatesCandidatesOnboardingRequested = 202,
	CandidatesCandidatesOnboardingCompleted = 203,

	//Candidate Requests
	CandidatesCandidatesBankRequested = 204,
	CandidatesCandidatesDocumentRequests = 205,
	CandidatesCandidateReferenceRequests = 206,

	//Candidate Expiries
	CandidatesDocumentsExpiring = 207,
	CandidatesOthersExpiring = 208,

	//Candidate Other
	CandidatesCandidatesInBookings = 209,
	CandidatesCandidateUtilisation = 210,
	CandidatesTotalCandidatePay = 211,

	////////////////////////

	//Timesheets
	TimesheetsTimesheetsCreated = 300,
	TimesheetsClientApproved = 301,
	TimesheetsCandidateApproved = 302,
	TimesheetsClientQueried = 303,
	TimesheetsCandidateQueried = 304,

	////////////////////////

	//Vacancies
	VacanciesVacancyCreate = 400,
	VacanciesVacancyComplete = 401,
	VacanciesVacancyCancelled = 402,

	////////////////////////

	//Clients
	ClientsClientCreate = 500,
	ClientsClientContactsUpdated = 501,
	ClientsClientUtilisation = 502,
	ClientsTotalClientSpend = 503,
	ClientsTotalClientProfit = 504,

	////////////////////////

	//Marketing
	MarketingCampaignsSent = 600,
	MarketingTotalRecipients = 601,
	MarketingSuccessfullyDelivered = 602,
	MarketingOpens = 603,
	MarketingClicks = 604,
	MarketingBounced = 605,
	MarketingDeferred = 606,

	////////////////////////

	//Others
	CommentTags = 700,
	DaysOut = 701,
	GrossProfit = 702,
	IndividualCandidatesBooked = 703,
	IndividualClientsBooked = 704,
	HoursOut = 705,
	ClientUtilisation = 706,

	////////////////////////
}
