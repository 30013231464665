import { HTTP, IHttpResponse } from "./http";
import {
	CREATE_USER,
	UPDATE_USER,
	UPDATE_USER_COMMISSION,
	UPDATE_LINKED_CLIENTS,
	TOGGLE_ACCOUNT_STATE,
	DELETE_USER,
	GET_SUBSCRIPTION_USERS,
	GET_SUBSCRIPTION_USERS_STUB,
	GET_USER,
	GET_USER_CLIENTS,
	GET_USER_AUTH_SESSION,
	RESEND_ACTIVATION_EMAIL,
	EMAIL_PREFERENCES,
	GET_SUBSCRIPTION_USERS_STUB_ALL,
	HIDE_ONBOARD,
	GET_TARGET_USERS,
} from "../../config/endpoints";
import { HeadersAuth } from "./http";
import { IEmailPreference } from "../../_models/data.emailPreference";

export const CreateUser = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: CREATE_USER,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateUser = (Token: string, DATA, USER_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_USER(USER_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateUserCommission = (Token: string, USER_ID: string, dateFrom: string) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_USER_COMMISSION(USER_ID),
		Data: {
			dateFrom,
		},
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateLinkedClients = (Token: string, DATA, USER_ID) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_LINKED_CLIENTS(USER_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ToggleAccountState = (Token: string, DATA, USER_ID) => {
	return HTTP({
		Method: "PUT",
		Url: TOGGLE_ACCOUNT_STATE(USER_ID),
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const DeleteUser = (Token: string, USER_ID) => {
	return HTTP({
		Method: "DELETE",
		Url: DELETE_USER(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetSubscriptionUsersServer = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_SUBSCRIPTION_USERS_STUB,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetSubscriptionUsers = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: GET_SUBSCRIPTION_USERS,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetSubscriptionUsersStub = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_SUBSCRIPTION_USERS_STUB,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetSubscriptionUsersStubAll = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_SUBSCRIPTION_USERS_STUB_ALL,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetUser = (Token: string, USER_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_USER(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetUserAuthSession = (Token: string, USER_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_USER_AUTH_SESSION(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const DeleteUserAuthSession = (Token: string, USER_ID) => {
	return HTTP({
		Method: "DELETE",
		Url: GET_USER_AUTH_SESSION(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ResendActivationEmail = (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: RESEND_ACTIVATION_EMAIL,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetEmailPreferences = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: EMAIL_PREFERENCES,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const UpdateEmailPreferences = (Token: string, DATA: IEmailPreference) => {
	return HTTP({
		Method: "PUT",
		Url: EMAIL_PREFERENCES,
		Headers: HeadersAuth(Token),
		Data: DATA,
	}) as Promise<IHttpResponse>;
};

export const GetUserClients = (Token: string, USER_ID) => {
	return HTTP({
		Method: "GET",
		Url: GET_USER_CLIENTS(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const HideOnboard = (Token: string, USER_ID) => {
	return HTTP({
		Method: "POST",
		Url: HIDE_ONBOARD(USER_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetTargetUsers = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_TARGET_USERS,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};
